import routes from 'routes';

export const checkPageLoaded = (
  pathname: string,
  rootPath: string | null = null
) => {
  const calledPage = localStorage.getItem('genTaxCalledPage');
  if (calledPage) {
    if (calledPage === pathname) {
      localStorage.removeItem('genTaxCalledPage');
    } else {
      if (
        rootPath &&
        pathname.includes(rootPath) &&
        !calledPage.includes(routes.main)
      ) {
        window.location.href = calledPage;
      }
    }

    // if (calledPage === pathname || (rootPath && pathname.includes(rootPath))) {
    //   localStorage.removeItem('genTaxCalledPage');
    // } else if (!calledPage.includes(routes.main)) {
    //   window.location.href = calledPage;
    // }
  }
  return false;
};
