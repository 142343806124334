import React from 'react';
import { Row, Typography } from 'antd';
import { TemplateMapping } from 'pages/returns';
import styled from 'styled-components';
import { ScopeButton } from 'components/Custom/buttons';
import { useTranslation } from 'react-i18next';
import { turnDrawerOff } from 'store/app/actions';
import { useDispatch } from 'react-redux';
import { RoundInput } from 'components/Custom/inputs';
import { SearchOutlined } from '@ant-design/icons';

const LoadEntityWrapper = styled.div`

  .entity-box {
    width: 100%;
    min-height: 80px;
    border-radius: 10px;
    border: 1px solid #E9F6F7
    opacity: 0px;
    background: #ffffff;
    display:'flex';
    padding: 15px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .entity-box-selected {
    border: 1px solid #41AAAF
  }
  .entity-name{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #042A2A;

  }
  .entity-other-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #879494;
  }
`;
interface ChooseTemplateProps {
  templateMappings: TemplateMapping[];
  loadExistingMapping: (mappingId: string, isTemplate?: boolean) => void;
}
const { Text } = Typography;
export const ChooseTemplate: React.FC<ChooseTemplateProps> = ({
  templateMappings,
  loadExistingMapping,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = React.useState<string>();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [templatesList, setTemplatesList] = React.useState<TemplateMapping[] | undefined>(
    []
  );

  React.useEffect(() => {
    setTemplatesList(templateMappings);
  }, [templateMappings]);

  const onLoadTemplate = (id: string) => {
    loadExistingMapping(id, true);
  };
  const handleClick = () => {
    selectedTemplate && onLoadTemplate(selectedTemplate);
    dispatch(turnDrawerOff());
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == '') {
      setTemplatesList(templateMappings);
    }
  };

  const handleSearch = (_e) => {
    if (searchValue != '') {
      setTemplatesList(
        templateMappings?.filter((template) =>
          template?.organisationName
            ?.toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        )
      );
    } else {
      setTemplatesList(templateMappings);
    }
  };

  return (
    <LoadEntityWrapper>
      <Row justify={'center'} align={'middle'} style={{ marginBottom: 25 }}>
      <RoundInput
          width="317px"
          height="45px" //@ts-ignore
          bg_color="white"
          size="large"
          style={{ marginBottom: '20px' }}
          value={searchValue}
          placeholder={`${t('common.search')} ${t('organisation.entities')}`}
          onChange={handleChange}
          suffix={
            <SearchOutlined
              width={33}
              height={33}
              style={{
                backgroundColor: '#41AAAF',
                color: 'white',
                padding: '8px',
                borderRadius: '50px',
                fontSize: '16px',
                cursor: 'pointer',
              }}
              onClick={handleSearch}
            />
          }
          />
      </Row>
      <Row justify={'center'} align={'middle'} style={{ marginBottom: 25 }}>
        <div className="scroller-entity">
          {templatesList?.sort((a, b) => a.organisationName.localeCompare(b.organisationName)).map((tm) => (
            <>
              {tm.organisationName && tm.country && tm.mappingId && (
                <div
                  className={`entity-box ${
                    selectedTemplate === tm.mappingId && 'entity-box-selected'
                  }`}
                  onClick={() => setSelectedTemplate(tm.mappingId)}
                  key={tm.mappingId}
                >
                  <span className="entity-name">{tm.organisationName}</span>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span className="entity-other-info">
                      {tm.country} | Created on {tm.createdDate}
                    </span>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </Row>
      <ScopeButton
        type="primary" //@ts-ignore
        width="100%"
        disabled={!selectedTemplate}
        onClick={handleClick}
      >
        {t('common.load')}
      </ScopeButton>
    </LoadEntityWrapper>
  );
};
