export const FETCH_CONNECT_URL_REQUEST = 'account/fetch-connect-url-request';
export const FETCH_CONNECT_URL_SUCCESS = 'account/fetch-connect-url-success';
export const FETCH_CONNECT_URL_FAILURE = 'account/fetch-connect-url-failure';

export const DISCONNECT_PROVIDER_REQUEST =
  'account/disconnect-provider-request';
export const DISCONNECT_PROVIDER_SUCCESS =
  'account/disconnect-provider-success';
export const DISCONNECT_PROVIDER_FAILURE =
  'account/disconnect-provider-failure';

export const FETCH_QR_CODE_REQUEST = 'account/fetch-qr-code-request';
export const FETCH_QR_CODE_SUCCESS = 'account/fetch-qr-code-success';
export const FETCH_QR_CODE_FAILURE = 'account/fetch-qr-code-failure';

export const ENABLE_MFA_REQUEST = 'account/enable-mfa-request';
export const ENABLE_MFA_SUCCESS = 'account/enable-mfa-success';
export const ENABLE_MFA_FAILURE = 'account/enable-mfa-failure';

export const DISABLE_MFA_REQUEST = 'account/disable-mfa-request';
export const DISABLE_MFA_SUCCESS = 'account/disable-mfa-success';
export const DISABLE_MFA_FAILURE = 'account/disable-mfa-failure';

export const UPDATE_DETAILS_REQUEST = 'account/update-details-request';
export const UPDATE_DETAILS_SUCCESS = 'account/update-details-success';
export const UPDATE_DETAILS_FAILURE = 'account/update-details-failure';

export const CHANGE_PASSWORD_REQUEST = 'account/change-password-request';
export const CHANGE_PASSWORD_SUCCESS = 'account/change-password-success';
export const CHANGE_PASSWORD_FAILURE = 'account/change-password-failure';

export const CHANGE_OWNER_REQUEST = 'account/change-owner-request';
export const CHANGE_OWNER_SUCCESS = 'account/change-owner-success';
export const CHANGE_OWNER_FAILURE = 'account/change-owner-failure';

export const DELETE_ACCOUNT_REQUEST = 'account/delete-account-request';
export const DELETE_ACCOUNT_SUCCESS = 'account/delete-account-success';
export const DELETE_ACCOUNT_FAILURE = 'account/delete-account-failure';

export const ADD_PASSWORD_REQUEST = 'account/add-password-request';
export const ADD_PASSWORD_SUCCESS = 'account/add-password-success';
export const ADD_PASSWORD_FAILURE = 'account/add-password-failure';

export const UPLOAD_PROFILE_PICTURE_REQUEST =
  'account/upload-profile-picture-request';
export const UPLOAD_PROFILE_PICTURE_SUCCESS =
  'account/upload-profile-picture-success';
export const UPLOAD_PROFILE_PICTURE_FAILURE =
  'account/upload-profile-picture-failure';

export const FETCH_PROFILE_PICTURE_REQUEST =
  'account/fetch-profile-picture-request';
export const FETCH_PROFILE_PICTURE_SUCCESS =
  'account/fetch-profile-picture-success';
export const FETCH_PROFILE_PICTURE_FAILURE =
  'account/fetch-profile-picture-failure';

export const RESET_ACCOUNT_DATA = 'account/reset-account-data';
