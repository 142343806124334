import { ConnectEntity } from 'components/ConnectEntity';
import { ConnectCin7 } from 'components/Entity/connect-cin7';
import { ChooseReturn } from 'components/MappingForm/choose-return';
import { OrganisationDetails } from 'components/OrganisationDetails';
import { EditFriendlyName } from 'components/OrganisationDetails/edit-friendly-name';
import { push } from 'connected-react-router';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import { VatOverviewPage } from 'pages/vat';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import routes from 'routes';
import { AccountType } from 'store/api/enums';
import {
  clearMappingData,
  turnDrawerOff,
  turnDrawerOn,
  turnModalOn,
} from 'store/app/actions';
import { DataLogStatus, DrawerType, WindowType } from 'store/app/enums';
import { DrawerData, ModalData } from 'store/app/types';
import { getAuthUser } from 'store/auth/selectors';
import { fetchCountriesRequest } from 'store/common/actions';
import { getCommonCountries } from 'store/common/selectors';
import {
  disconnectOrganisationsRequest,
  editOrganisationsRequest,
  fetchOrganisationsRequest,
  updateOrganisationData,
} from 'store/organisation/actions';
import {
  EditOrganisationParams,
  Mapping,
  Organisation,
} from 'store/organisation/models';
import {
  getOrgLoading,
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import { fetchAddReturnDataRequest } from 'store/returns/actions';
import { ReturnData } from 'store/returns/models';
import { getReturnData } from 'store/returns/selectors';
import { activateViesRequest } from 'store/vat-checker/actions';
import { fetchVatLogsRequest } from 'store/vat/actions';
import { getVatReturnLogs } from 'store/vat/selectors';
import { checkPageLoaded } from 'utils/check-page-loaded';

export const OrganisationPage: React.FC = () => {
  const parameters: any = useParams();
  const dispatch = useDispatch();
  const selectedId = parameters.id;
  const organisations = useSelector(getOrgOrganisations);
  const mappings = useSelector(getOrgMappings);
  const loading = useSelector(getOrgLoading);
  const currentUser = useSelector(getAuthUser);
  const countries = useSelector(getCommonCountries);
  const returnData = useSelector(getReturnData);
  const vatReturnLogs = useSelector(getVatReturnLogs);
  const [selectedOrg, setSelectedOrg] = React.useState<Organisation>();
  const [selectedMaps, setSelectedMaps] = React.useState<Mapping[]>([]);
  // const [selectedVatReturnLogs, setSelectedVatReturnLogs] = React.useState<
  //   VatReturnLog[]
  // >([]);
  const { t } = useTranslation();

  const disconnectOrganisation = (organisationId: string) => {
    dispatch(disconnectOrganisationsRequest(organisationId));
  };

  React.useEffect(() => {
    if (!organisations) {
      dispatch(fetchOrganisationsRequest());
      countries.length = 0 && dispatch(fetchCountriesRequest());
    }
    currentUser && checkPageLoaded(location.pathname, routes.organisations.all);
  }, []);

  React.useEffect(() => {
    if (organisations) {
      const org = organisations.find((o) => o.uuid === selectedId);
      setSelectedOrg(org);
    }
  }, [JSON.stringify(organisations), selectedMaps]);

  React.useEffect(() => {
    if (selectedOrg && selectedMaps) {
      const mapIds: string[] = [];
      selectedMaps.forEach((sm) => mapIds.push(sm.mappingId));
      dispatch(fetchVatLogsRequest(selectedOrg.connectionId, mapIds));
    }
  }, [selectedOrg, selectedMaps]);

  const onAddEntity = () => {
    const drawerData: DrawerData = {
      title: 'Connect an entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  React.useEffect(() => {
    if (mappings) {
      const maps: Mapping[] = mappings.filter(
        (m) => m.organisationId === selectedId
      );
      setSelectedMaps(maps);
    }
  }, [mappings]);

  const openEditFriendlyName = () => {
    if (selectedOrg) {
      const drawerData: DrawerData = {
        title: t('organisation.edit-friendly-name'),
        type: DrawerType.EDIT_FRIENDLY_NAME,
        component: (
          <EditFriendlyName
            friendlyName={selectedOrg.friendlyName}
            editOrganisationName={editOrganisationName}
            onClose={() => dispatch(turnDrawerOff())}
          />
        ),
      };
      dispatch(turnDrawerOn(drawerData));
    } else alert('Missing connection Id.');
  };

  const editOrganisationName = (newName?: string) => {
    if (!newName) return;
    const newDetails: EditOrganisationParams = {
      newName,
      clientCode: undefined,
      officeLocation: undefined,
      refId: selectedId,
    };

    dispatch(editOrganisationsRequest(newDetails));
    dispatch(turnDrawerOff());
  };

  const addReturn = () => {
    if (selectedOrg) {
      const drawerData: DrawerData = {
        title: 'Add a Tax Return',
        type: DrawerType.ADD_TAX_RETURN,
        component: <ChooseReturn editMode={false} />,
      };
      if (!returnData || returnData.connectionId !== selectedOrg.connectionId) {
        dispatch(fetchAddReturnDataRequest(selectedOrg.connectionId));
      }
      dispatch(clearMappingData());
      dispatch(turnDrawerOn(drawerData));
    } else alert('Missing connection Id.');
  };

  const editReturnMapping = (connectionId: string, mappingId: string) => {
    // const selectedOrg = organisations?.find(
    //   (mo) => mo.connectionId === connectionId
    // );
    // const returnTypes = selectedOrg?.returnTypes;
    // const returnTypesEdit = selectedOrg?.returnTypesEdit;
    // const taxRates = selectedOrg?.taxRates;
    // const selMapping = selectedMaps.find((r) => r.mappingId === mappingId);
    // const sections = selMapping?.sections ? selMapping.sections : [];
    // const retData: ReturnData = {
    //   returnTypes,
    //   returnTypesEdit,
    //   sections,
    //   connectionId,
    //   taxRates,
    // };
    // dispatch(fetchAddReturnDataRequest(connectionId, retData));
    if (connectionId && mappingId) {
      dispatch(
        push(
          routes.returns.edit
            .replace(':connectionId', connectionId)
            .replace(':mappingId', mappingId)
        )
      );
    } else alert('Missing connection Id.');
  };

  const openVatWindow = (
    connectionId: string,
    mappingId: string
    //dataLogId?: string
  ) => {
    const modalData: DrawerData = {
      type: DrawerType.ADD_TAX_SUBMISSION,
      title: 'Create Tax Submission',
      component: (
        <VatOverviewPage connectionId={connectionId} mappingId={mappingId} />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const vatGenerate = (
    connectionId: string,
    mappingId: string,
    dataLogId: string
  ) => {
    const calledConfirmed = () =>
      vatGenerate2(connectionId, mappingId, dataLogId);
    const description = (
      <div>
        The way <strong>Output Tax</strong> and <strong>Input Tax</strong> is
        calculated has changed. Previously, it was system-generated. However,
        we've now introduced an improved Reverse charge mechanism.
        <br />
        <br /> Before creating a new tax submission, please go to the mapping
        page and edit the mapping for output and input tax boxes for this entity
        (You will need to cancel any open tax submissions before editing the
        mapping). <br />
        <br />
        Refer to the What's New page for more information about this new
        feature.{' '}
        <strong>
          {' '}
          You will also need to specify which tax rates are of type Input and
          output.
        </strong>
      </div>
    );

    const modalData: ModalData = {
      type: WindowType.WARNING,
      title: 'System Message',
      component: (
        <ConfirmBoxContainer
          callback={calledConfirmed}
          showCancelBtn={true}
          okText="Continue"
          description={description}
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const vatGenerate2 = (
    connectionId: string,
    mappingId: string,
    dataLogId: string
  ) => {
    dataLogId
      ? dispatch(
          push(
            routes.vat.viewReportLink
              .replace(':connectionId', connectionId)
              .replace(':mappingId', mappingId)
              .replace(':dataLogId', dataLogId)
          )
        )
      : openVatWindow(connectionId, mappingId);
  };

  const checkVatReturn = () => {
    if (vatReturnLogs) {
      if (currentUser) {
        if (AccountType[currentUser.accountType] === AccountType.CLIENT) {
          return vatReturnLogs.filter(
            (vrl) =>
              DataLogStatus[vrl.status] ===
                DataLogStatus.AWAITING_CLIENT_APPROVAL ||
              DataLogStatus[vrl.status] === DataLogStatus.CANCELLED ||
              DataLogStatus[vrl.status] === DataLogStatus.CLIENT_APPROVED ||
              DataLogStatus[vrl.status] === DataLogStatus.LOCKED ||
              DataLogStatus[vrl.status] === DataLogStatus.SUBMITTED
          );
        }
      }
    }
    return vatReturnLogs;
  };

  const loadCustomersAndSuppliers = (connectionId: string) => {
    dispatch(
      push(
        routes.vies.customersAndSuppliers.replace(':connectionId', connectionId)
      )
    );
  };

  const activateVatChecker = (
    connectionId: string,
    entityName: string,
    uuid: string
  ) => {
    dispatch(activateViesRequest(connectionId, entityName));
    dispatch(updateOrganisationData(uuid, true));
    dispatch(
      push(
        routes.vies.customersAndSuppliers.replace(':connectionId', connectionId)
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{`${selectedOrg?.friendlyName} | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a home page" />
      </Helmet>

      <OrganisationDetails
        loading={loading}
        organisation={selectedOrg}
        selectedMaps={selectedMaps}
        vatReturnLogs={checkVatReturn()}
        disconnectOrganisation={disconnectOrganisation}
        countries={countries}
        openEditFriendlyName={openEditFriendlyName}
        addReturn={addReturn}
        editReturnMapping={editReturnMapping}
        vatGenerate={vatGenerate}
        connectOrganisation={onAddEntity}
        currentUser={currentUser}
        loadCustomersAndSuppliers={loadCustomersAndSuppliers}
        activateVatChecker={activateVatChecker}
      />
    </>
  );
};
