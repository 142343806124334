import moment from 'moment';
import { Divider, Radio, Space, Typography } from 'antd';
import * as React from 'react';
import { DataJournal } from 'store/vat/models/vatReportData';
import { StyledCard } from 'components/Custom/card';
import { ScopeTable } from 'components/Custom/table';
import { ExportButton } from 'components/Custom/buttons';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { ReactComponent as IconExport } from 'assets/images/icons/iconExport.svg';
import { RoundInput } from 'components/Custom/inputs';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

interface RenderTRansactionProps {
  transData?: DataJournal[];
  isException: boolean;
  dataToExport?: DataJournal[];
  journalType?: string;
  setJournalType?: (value: any) => void;
  filteredBox?: string | null;
  noSumData?: boolean;
}

const { Text } = Typography;
const providerName = 'xero';

export const RenderTransactions: React.FC<RenderTRansactionProps> = ({
  transData,
  isException,
  dataToExport,
  journalType,
  setJournalType,
  filteredBox,
  noSumData,
}) => {
  //const [searchedResult, setSearchedResult] = React.useState(transData);
  const [source, setSource] = React.useState(transData);
  const { t } = useTranslation();

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };
  React.useEffect(() => {
    setSource(transData);
  }, [transData]);
  const columns = [
    {
      width: 50,
      render: (_, record: DataJournal) => (
        <a
          href={record.sourceUrl}
          style={{ cursor: 'pointer' }}
          target={'_blank'}
          rel="noreferrer"
        >
          <img
            src={
              providerName
                ? require(`shared/assets/images/${providerName.toLowerCase()}_small.png`)
                    .default
                : ''
            }
            style={{ marginTop: 7 }}
            width={30}
          />
        </a>
      ),
    },
    {
      title: 'Tax Name',
      dataIndex: 'taxName',
      key: 'taxName',
      ellipsis: true,
      render: (content: string) => (
        <Text
          style={{ minWidth: 145, maxWidth: 160 }}
          //ellipsis={{ rows: 1, tooltip: true }}
        >
          {content}
        </Text>
      ),
      sorter: (a, b) => a.taxName?.localeCompare(b.taxName),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Mapping',
      dataIndex: 'selectedValue',
      key: 'selectedValue',
      sorter: (a, b) => a.selectedValue?.localeCompare(b.selectedValue),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Journal Date',
      dataIndex: 'journalDate',
      key: 'journalDate',
      ellipsis: true,
      render: (_, record: DataJournal) =>
        moment(record.journalDate).format('DD/MM/YYYY hh:mm'),
      sorter: (a, b) =>
        new Date(a.journalDate).valueOf() - new Date(b.journalDate).valueOf(),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Source Type',
      dataIndex: 'sourceType',
      key: 'sourceType',
      ellipsis: true,
      sorter: (a, b) => a.sourceType?.localeCompare(b.sourceType),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Code',
      dataIndex: 'accountCode',
      key: 'accountCode',
      sorter: (a, b) => a.accountCode?.localeCompare(b.accountCode),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Type',
      dataIndex: 'accountType',
      key: 'accountType',
      ellipsis: true,
      sorter: (a, b) => a.accountType?.localeCompare(b.accountType),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Name',
      dataIndex: 'accountName',
      key: 'accountName',
      ellipsis: true,
      render: (content: string) => (
        <Text
          style={{ minWidth: 100, maxWidth: 130 }}
          //ellipsis={{ rows: 1, tooltip: true }}
        >
          {content}
        </Text>
      ),
      sorter: (a, b) => a.accountName?.localeCompare(b.accountName),
      sortIcon: renderSortIcon,
    },
    /*     {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (content: string) => (
        <div style={{ maxWidth: 300 }}>{content}</div>
      ),
    },*/
    {
      title: 'Net Amount',
      dataIndex: 'netAmountConverted',
      key: 'netAmountConverted',
      render: (content: number) => (
        <div
          style={{
            textAlign: 'end',
            color: content < 0 ? '#d13131' : '',
          }}
        >
          {content.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => (a.netAmountConverted > b.netAmountConverted ? -1 : 1),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmountConverted',
      key: 'taxAmountConverted',
      render: (content: number) => (
        <div style={{ textAlign: 'end', color: content < 0 ? '#d13131' : '' }}>
          {content.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => (a.taxAmountConverted > b.taxAmountConverted ? -1 : 1),
      sortIcon: renderSortIcon,
    },
  ];

  if (isException) {
    const warningColumn: any = {
      title: 'Warning',
      dataIndex: 'error',
      key: 'error',
    };
    columns.push(warningColumn);
  }

  const getHeaderForExport = (dataToExport) => {
    const obj = dataToExport?.length > 0 ? dataToExport[0] : null;
    let headerArr: any = [];
    if (obj) {
      const headers = Object.keys(obj);
      headerArr = headers.map((key) => ({
        label: key.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
        key: key,
      }));
    }

    return headerArr;
  };

  const search = (e) => {
    const value = e.target.value;
    if (value != '') {
      const filterTable = transData?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase())
        )
      );

      setSource(filterTable);
    } else {
      setSource(transData);
    }
  };

  const renderCardTitle = () => {
    return (
      <Space
        style={{
          display: 'flex',
          marginBottom: 20,
          justifyContent: 'space-between',
        }}
      >
        <Space>
          {!isException && (
            <Radio.Group
              defaultValue={journalType}
              buttonStyle="solid"
              style={{ borderRadius: '10px' }} //@ts-ignore
              onChange={(e) => setJournalType(e.target.value)}
            >
              <Radio.Button value={'SUMMARISED'} disabled={noSumData}>
                {t('transactions.summarised')}
              </Radio.Button>
              <Radio.Button value={'DETAILED'}>
                {' '}
                {t('transactions.detailed')}
              </Radio.Button>
            </Radio.Group>
          )}

          <ExportButton icon={<IconExport />}>
            <CSVLink
              filename={`${
                journalType == 'SUMMARISED' ? 'Summarised' : 'Detailed'
              } ${isException ? 'Exceptions' : 'Transactions'} Export.csv`}
              data={dataToExport}
              headers={getHeaderForExport(dataToExport)}
            >
              <span>Export {isException ? 'Exceptions' : 'Transactions'}</span>
            </CSVLink>
          </ExportButton>
        </Space>

        <RoundInput
          width={'200px'}
          height={'35px'}
          prefix={<SearchOutlined />}
          size="large"
          placeholder={
            'Search ' + (isException ? 'Exceptions' : 'Transactions')
          }
          onChange={search}
        />
      </Space>
    );
  };

  return (
    <>
      <StyledCard //@ts-ignore
        width="100%"
      >
        {transData && transData?.length > 0 && renderCardTitle()}
        <Divider />
        <div
          style={{
            padding: '10px',
            paddingLeft: '5px',
            paddingTop: '10px',
            //maxWidth: 1600,
            minWidth: 800,
            margin: 'auto',
          }}
        >
          <ScopeTable
            filters={[
              'taxName',
              'selectedValue',
              'journalDate',
              'sourceType',
              'accountCode',
              'accountType',
              'accountName',
            ]} //@ts-ignore
            mainLocaleKey="transactions"
            tableDataSource={source}
            originalDataSource={transData}
            columns={columns}
            pagination={true}
            rowsPerPage={15}
            enableColumnsCustomization={false}
            rowHeight={40} //@ts-ignore
            selectedFilter={{ key: 'selectedValue', value: filteredBox }}
          />
        </div>
      </StyledCard>
    </>
  );
};
