import { createAction } from 'store/utils';
import {
  AddPasswordFailureAction,
  AddPasswordRequestAction,
  AddPasswordSuccessAction,
  ChangeOwnerFailureAction,
  ChangeOwnerRequestAction,
  ChangeOwnerSuccessAction,
  ChangePasswordFailureAction,
  ChangePasswordRequestAction,
  ChangePasswordSuccessAction,
  DeleteAccountFailureAction,
  DeleteAccountRequestAction,
  DeleteAccountSuccessAction,
  DisableMfaFailureAction,
  DisableMfaRequestAction,
  DisableMfaSuccessAction,
  DisconnectProviderFailureAction,
  DisconnectProviderRequestAction,
  DisconnectProviderSuccessAction,
  EnableMfaFailureAction,
  EnableMfaRequestAction,
  EnableMfaSuccessAction,
  FetchConnectUrlFailureAction,
  FetchConnectUrlRequestAction,
  FetchConnectUrlSuccessAction,
  FetchProfilePictureFailureAction,
  FetchProfilePictureRequestAction,
  FetchProfilePictureSuccessAction,
  FetchQRCodeFailureAction,
  FetchQRCodeRequestAction,
  FetchQRCodeSuccessAction,
  ResetAccountDataAction,
  UpdateDetailsFailureAction,
  UpdateDetailsRequestAction,
  UpdateDetailsSuccessAction,
  UploadProfilePictureFailureAction,
  UploadProfilePictureRequestAction,
  UploadProfilePictureSuccessAction,
} from './types';
import {
  ADD_PASSWORD_FAILURE,
  ADD_PASSWORD_REQUEST,
  ADD_PASSWORD_SUCCESS,
  CHANGE_OWNER_FAILURE,
  CHANGE_OWNER_REQUEST,
  CHANGE_OWNER_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DISABLE_MFA_FAILURE,
  DISABLE_MFA_REQUEST,
  DISABLE_MFA_SUCCESS,
  DISCONNECT_PROVIDER_FAILURE,
  DISCONNECT_PROVIDER_REQUEST,
  DISCONNECT_PROVIDER_SUCCESS,
  ENABLE_MFA_FAILURE,
  ENABLE_MFA_REQUEST,
  ENABLE_MFA_SUCCESS,
  FETCH_CONNECT_URL_FAILURE,
  FETCH_CONNECT_URL_REQUEST,
  FETCH_CONNECT_URL_SUCCESS,
  FETCH_PROFILE_PICTURE_FAILURE,
  FETCH_PROFILE_PICTURE_REQUEST,
  FETCH_PROFILE_PICTURE_SUCCESS,
  FETCH_QR_CODE_FAILURE,
  FETCH_QR_CODE_REQUEST,
  FETCH_QR_CODE_SUCCESS,
  RESET_ACCOUNT_DATA,
  UPDATE_DETAILS_FAILURE,
  UPDATE_DETAILS_REQUEST,
  UPDATE_DETAILS_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAILURE,
  UPLOAD_PROFILE_PICTURE_REQUEST,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
} from './constants';
import { Provider } from 'store/api/enums';
import { QRCodeData } from './models';
import { User } from 'store/auth/models';

// Fetch Connect Url
export const fetchConnectUrlRequest = (
  provider: Provider,
  providerAction: string
): FetchConnectUrlRequestAction => {
  return createAction(FETCH_CONNECT_URL_REQUEST, { provider, providerAction });
};

export const fetchConnectUrlSuccess = (): FetchConnectUrlSuccessAction =>
  createAction(FETCH_CONNECT_URL_SUCCESS);

export const fetchAddUrlFailure = (): FetchConnectUrlFailureAction =>
  createAction(FETCH_CONNECT_URL_FAILURE);

// Disconnect Provider
export const disconnectProviderRequest = (
  provider: Provider
): DisconnectProviderRequestAction => {
  return createAction(DISCONNECT_PROVIDER_REQUEST, { provider });
};
export const disconnectProviderSuccess = (): DisconnectProviderSuccessAction =>
  createAction(DISCONNECT_PROVIDER_SUCCESS);

export const disconnectProviderFailure = (): DisconnectProviderFailureAction =>
  createAction(DISCONNECT_PROVIDER_FAILURE);

// Fetch Connect Url
export const fetchQRCodeRequest = (): FetchQRCodeRequestAction =>
  createAction(FETCH_QR_CODE_REQUEST);

export const fetchQRCodeSuccess = (
  QRCodeData: QRCodeData
): FetchQRCodeSuccessAction =>
  createAction(FETCH_QR_CODE_SUCCESS, { QRCodeData });

export const fetchQRCodeFailure = (): FetchQRCodeFailureAction =>
  createAction(FETCH_QR_CODE_FAILURE);

// Enable MFA
export const enableMfaRequest = (
  code: string,
  secretKey: string
): EnableMfaRequestAction =>
  createAction(ENABLE_MFA_REQUEST, { code, secretKey });

export const enableMfaSuccess = (): EnableMfaSuccessAction =>
  createAction(ENABLE_MFA_SUCCESS);

export const enableMfaFailure = (): EnableMfaFailureAction =>
  createAction(ENABLE_MFA_FAILURE);

// Disable MFA
export const disableMfaRequest = (): DisableMfaRequestAction =>
  createAction(DISABLE_MFA_REQUEST);

export const disableMfaSuccess = (): DisableMfaSuccessAction =>
  createAction(DISABLE_MFA_SUCCESS);

export const disableMfaFailure = (): DisableMfaFailureAction =>
  createAction(DISABLE_MFA_FAILURE);

// Update Details
export const updateDetailsRequest = (user: User): UpdateDetailsRequestAction =>
  createAction(UPDATE_DETAILS_REQUEST, { user });

export const updateDetailsSuccess = (): UpdateDetailsSuccessAction =>
  createAction(UPDATE_DETAILS_SUCCESS);

export const updateDetailsFailure = (): UpdateDetailsFailureAction =>
  createAction(UPDATE_DETAILS_FAILURE);

// Change Password
export const changePasswordRequest = (
  oldPassword: string,
  newPassword: string
): ChangePasswordRequestAction =>
  createAction(CHANGE_PASSWORD_REQUEST, { oldPassword, newPassword });

export const changePasswordSuccess = (): ChangePasswordSuccessAction =>
  createAction(CHANGE_PASSWORD_SUCCESS);

export const changePasswordFailure = (): ChangePasswordFailureAction =>
  createAction(CHANGE_PASSWORD_FAILURE);

// Change Owner
export const changeOwnerRequest = (
  accountId: string
): ChangeOwnerRequestAction =>
  createAction(CHANGE_OWNER_REQUEST, { accountId });

export const changeOwnerSuccess = (): ChangeOwnerSuccessAction =>
  createAction(CHANGE_OWNER_SUCCESS);

export const changeOwnerFailure = (): ChangeOwnerFailureAction =>
  createAction(CHANGE_OWNER_FAILURE);

// Delete account
export const deleteAccountRequest = (): DeleteAccountRequestAction =>
  createAction(DELETE_ACCOUNT_REQUEST);

export const deleteAccountSuccess = (): DeleteAccountSuccessAction =>
  createAction(DELETE_ACCOUNT_SUCCESS);

export const deleteAccountFailure = (): DeleteAccountFailureAction =>
  createAction(DELETE_ACCOUNT_FAILURE);

// Upload profile picture
export const uploadProfilePictureRequest = (
  base64: File,
  userId: string
): UploadProfilePictureRequestAction =>
  createAction(UPLOAD_PROFILE_PICTURE_REQUEST, { base64, userId });

export const uploadProfilePictureSuccess =
  (): UploadProfilePictureSuccessAction =>
    createAction(UPLOAD_PROFILE_PICTURE_SUCCESS);

export const uploadProfilePictureFailure =
  (): UploadProfilePictureFailureAction =>
    createAction(UPLOAD_PROFILE_PICTURE_FAILURE);

// fetch profile picture
export const fetchProfilePictureRequest = (
  userId: string
): FetchProfilePictureRequestAction =>
  createAction(FETCH_PROFILE_PICTURE_REQUEST, { userId });

export const fetchProfilePictureSuccess = (
  profilePicture: string | undefined
): FetchProfilePictureSuccessAction =>
  createAction(FETCH_PROFILE_PICTURE_SUCCESS, { profilePicture });

export const fetchProfilePictureFailure =
  (): FetchProfilePictureFailureAction =>
    createAction(FETCH_PROFILE_PICTURE_FAILURE);

// RESET
export const resetAccountData = (): ResetAccountDataAction =>
  createAction(RESET_ACCOUNT_DATA);


// Add Password
export const addPasswordRequest = (
  newPassword: string
): AddPasswordRequestAction =>
  createAction(ADD_PASSWORD_REQUEST, { newPassword });

export const addPasswordSuccess = (): AddPasswordSuccessAction =>
  createAction(ADD_PASSWORD_SUCCESS);

export const addPasswordFailure = (): AddPasswordFailureAction =>
  createAction(ADD_PASSWORD_FAILURE);
