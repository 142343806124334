import { Space, Spin, Typography, Popover } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Country } from 'store/common/models';
import {
  DeleteOutlined,
  EditOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { User } from 'store/auth/models';
import { AccountType } from 'store/api/enums';
import { ScopeTable } from 'components/Custom/table';
import {
  ScopeButton,
  IconContextButton,
  ContextButton,
  IconButton,
} from 'components/Custom/buttons';
import {
  StrongTag,
  DashboardContainer,
  StrongTagDisabled,
} from 'components/Custom/spans';
import { DataLogStatus, dashboardStatistic } from 'store/app/enums';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as EyeIcon } from 'assets/images/icons/eyeIcon.svg';
import { ReactComponent as ConnectIcon } from 'assets/images/icons/connectIcon.svg';
import { ReactComponent as ClockIcon } from 'assets/images/icons/clockIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/images/icons/warningIcon.svg';
import { ReactComponent as UserIcon } from 'assets/images/icons/userIcon.svg';
import { ReactComponent as RefreshIcon } from 'assets/images/icons/refreshIcon.svg';
import { MappedReturn } from 'store/returns/models/returnData';
import { AvatarSizes, renderAvatar } from 'utils/render-avatar';
import { StatusGetter } from 'utils/status-getter';
import { EmptyPage } from 'containers/EmptyPage';
import * as _ from 'lodash';
import moment from 'moment';
import { canEditTaxReturn } from 'utils/canEditTaxReturn';
import { StyledTooltip } from 'components/Custom/Tooltip';
import { StyledPopconfirm } from 'components/Custom/Popconfirm';

interface MainComponentProps {
  loading: boolean;
  mappedReturns?: MappedReturn[];
  countries: Country[];
  addReturnMapping: (connectionId: string) => void;
  editReturnMapping: (connectionId: string, mappingId: string) => void;
  deleteReturnMapping: (connectionId: string, mappingId: string) => void;
  vatGenerate: (
    connectionId: string,
    mappingId: string,
    dataLogId?: string
  ) => void;
  currentUser?: User;
  emptyPageCallback: () => void;
  connectEntity: () => void;
  hasEntities?: boolean;
  gettingTaxes: boolean;
}

const { Text } = Typography;

export const MainComponent: React.FC<MainComponentProps> = ({
  loading,
  mappedReturns,
  editReturnMapping,
  deleteReturnMapping,
  vatGenerate,
  emptyPageCallback,
  connectEntity,
  currentUser,
  hasEntities,
  gettingTaxes,
}) => {
  const BUTTON_TEXT = 'BUTTON_TEXT';
  const LOADING = 'LOADING';
  const DISABLE = 'DISABLE';
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState(mappedReturns);
  const [selectedContanier, setSelectedContanier] = useState('');
  const [updateDueDateFilter, setUpdateDueDateFilter] = useState(false);
  const [updateStatusFilter, setUpdateStatusFilter] = useState(false);

  useEffect(() => {
    setDataSource(mappedReturns);
  }, [mappedReturns]);

  const renderOrgLink = (content: string, record: MappedReturn) => {
    const to = `/entities/organisation/${record.organisationId}`;

    return (
      <Space size={'small'}>
        <StyledTooltip
          key={record.organisationId}
          placement="top"
          title={t('main.click-to-view-details')}
        >
          <StrongTag>
            <Link to={to}>
              <Text style={{ fontSize: '13px' }}>{content}</Text>
            </Link>
          </StrongTag>
        </StyledTooltip>
      </Space>
    );
  };

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };

  const renderTaxReturn = (content, record: MappedReturn) => {
    return (
      <Space style={{ minWidth: '280px' }} size={'small'}>
        <StrongTagDisabled>{content}</StrongTagDisabled>
      </Space>
    );
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -300;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const renderContextMenu = (record) => {
    const to = `entities/organisation/${record.organisationId}#${record.mappingId}`;
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          {!checkLoading(record, DISABLE) && record.connectedProperly && (
            <IconContextButton
              type="text"
              icon={
                <PlusCircleOutlined style={{ width: '18px', height: '22px' }} />
              }
              onClick={() => {
                vatGenerate(record.connectionId, record.mappingId, undefined);
              }}
            >
              {t('organisation.add-submission')}
            </IconContextButton>
          )}
          <IconContextButton
            type="text"
            icon={<EyeIcon style={{ width: '18px', height: '22px' }} />}
          >
            <HashLink
              smooth
              style={{ textDecoration: 'none' }}
              to={to}
              scroll={(el) => scrollWithOffset(el)}
            >
              {t('organisation.view-previous-submission')}
            </HashLink>
          </IconContextButton>
          {!record.preparer &&
            currentUser &&
            AccountType[currentUser.accountType] !== AccountType.CLIENT &&
            currentUser?.canManageOrgs &&
            canEditTaxReturn(
              record.connectedProperly,
              currentUser,
              record.dataLog?.status
            ) && (
              <IconContextButton
                type="text"
                icon={
                  <EditOutlined style={{ width: '18px', height: '18px' }} />
                }
              >
                <Link
                  style={{ textDecoration: 'none' }}
                  to={'#'}
                  onClick={() =>
                    editReturnMapping(record.connectionId, record.mappingId)
                  }
                >
                  {t('organisation.edit-return')}
                </Link>
              </IconContextButton>
            )}
          {record.canDelete && (
            <StyledPopconfirm // @ts-ignore
              title="Cancel Tax Return."
              description="This action will cancel Tax Return, continue?"
              okText="Yes"
              cancelText="No"
              onConfirm={() =>
                deleteReturnMapping(record.organisationId, record.mappingId)
              }
            >
              <IconContextButton
                type="text"
                icon={
                  <DeleteOutlined style={{ width: '18px', height: '18px' }} />
                }
              >
                {t('organisation.cancel-return')}
              </IconContextButton>
            </StyledPopconfirm>
          )}
        </Space>
      </div>
    );
  };

  const renderDueDate = (content, _record: MappedReturn) => {
    const dueDate = new Date(content);
    const currentDate = new Date();

    return (
      <span
        className={
          dueDate.valueOf() < currentDate.valueOf() ? 'expired-due-date' : ''
        }
      >
        {content && moment(content).format('DD/MM/YYYY')}
      </span>
    );
  };

  const calcDueReturns = () => {
    return mappedReturns?.filter(
      //@ts-ignore
      (item) => new Date(item.nextDueDate).valueOf() > new Date().valueOf()
    )?.length;
  };

  const calcExpiredReturns = () => {
    return mappedReturns?.filter(
      //@ts-ignore
      (item) => new Date(item.nextDueDate).valueOf() < new Date().valueOf()
    )?.length;
  };

  const calcAwaitingClientReturns = () => {
    return mappedReturns?.filter(
      (item) => item.status == DataLogStatus.AWAITING_CLIENT_APPROVAL
    )?.length;
  };

  const filterDueReturns = (key) => {
    setSelectedContanier(key);
    switch (key) {
      case dashboardStatistic.taxReturns:
        setDataSource(mappedReturns);
        setUpdateDueDateFilter(false);
        setUpdateStatusFilter(false);
        break;
      case dashboardStatistic.dueReturns:
        setDataSource(
          mappedReturns?.filter(
            (
              item //@ts-ignore
            ) => new Date(item.nextDueDate).valueOf() > new Date().valueOf()
          )
        );
        setUpdateDueDateFilter(true);
        setUpdateStatusFilter(false);
        break;
      case dashboardStatistic.expiredReturns:
        setDataSource(
          mappedReturns?.filter(
            (
              item //@ts-ignore
            ) => new Date(item.nextDueDate).valueOf() < new Date().valueOf()
          )
        );
        setUpdateDueDateFilter(false);
        setUpdateStatusFilter(false);
        break;
      case dashboardStatistic.awaitingClient:
        setDataSource(
          mappedReturns?.filter(
            (item) => item.status == DataLogStatus.AWAITING_CLIENT_APPROVAL
          )
        );
        setUpdateDueDateFilter(false);
        setUpdateStatusFilter(true);
        break;
    }
  };

  const checkReconnectedBtn = (record: MappedReturn) => {
    if (!record.dataLog && !record.connectedProperly) {
      return true;
    }
    return false;
  };

  const checkLoading = (record: MappedReturn, item: string) => {
    let btnText = ' ';
    let load = false;
    let dis = false;
    if (record.dataLog) {
      if (
        (record.status === DataLogStatus.AWAITING_APPROVAL &&
          record.dataLog.reviewerUser?.userUuid === currentUser?.userUuid) ||
        (record.status === DataLogStatus.AWAITING_CLIENT_APPROVAL &&
          record.dataLog.clientUser?.userUuid === currentUser?.userUuid)
      ) {
        btnText = t('main.review');
      } else {
        btnText = t('main.open');
      }
    } else {
      if (record.connectedProperly) {
        btnText = t('main.create');
      } else if (gettingTaxes) {
        dis = true;
        load = true;
        btnText = ' ';
      }
    }
    if (item === BUTTON_TEXT) return btnText;
    if (item === LOADING) return load;
    if (item === DISABLE) return dis;
  };

  const checkForClient = (record: MappedReturn) => {
    if (
      currentUser?.accountType &&
      AccountType[currentUser?.accountType] === AccountType.CLIENT
    ) {
      if (
        record.status === DataLogStatus.AWAITING_CLIENT_APPROVAL ||
        record.status === DataLogStatus.CLIENT_APPROVED ||
        record.status === DataLogStatus.CANCELLED ||
        record.status === DataLogStatus.LOCKED ||
        record.status === DataLogStatus.SUBMITTED
      )
        return true;
      else return false;
    } else return true;
  };

  const columns = [
    {
      title: t('main.mappingName'),
      dataIndex: 'mappingName',
      key: 'mappingName',
      render: renderTaxReturn,
      sorter: (a, b) => a.mappingName.localeCompare(b.mappingName),
      sortIcon: renderSortIcon,
    },
    {
      title: t('main.organisation'),
      dataIndex: 'organisation',
      key: 'organisation',
      render: renderOrgLink,
      width: '140px',
      sorter: (a, b) => a.organisation.localeCompare(b.organisation),
      sortIcon: renderSortIcon,
    },
    {
      title: t('main.nextDueDate'),
      dataIndex: 'nextDueDate',
      key: 'nextDueDate',
      render: renderDueDate,
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        new Date(a.nextDueDate).valueOf() - new Date(b.nextDueDate).valueOf(),
      sortIcon: renderSortIcon,
    },
    {
      title: t('main.preparer'),
      dataIndex: 'preparer',
      key: 'preparer',
      sorter: (a, b) =>
        a.dataLog?.preparerUser?.firstName.localeCompare(
          b.dataLog?.preparerUser?.firstName
        ),
      sortIcon: renderSortIcon,
      render: (_, record: MappedReturn) => {
        if (
          currentUser &&
          AccountType[currentUser.accountType] === AccountType.CLIENT
        )
          return;
        if (record.dataLog?.preparerUser) {
          const userId = record.dataLog.preparerUser.userUuid;
          const firstName = record.dataLog.preparerUser.firstName;
          const lastName = record.dataLog.preparerUser.lastName;
          const userPic = record.dataLog.preparerUser.profilePicture;
          return renderAvatar(
            firstName,
            lastName,
            AvatarSizes.DEFAULT,
            userId,
            userPic
          );
        }
      },
      responsive: ['lg'],
    },
    {
      title: t('main.reviewer'),
      dataIndex: 'reviewer',
      key: 'reviewer',
      sorter: (a, b) =>
        a.dataLog?.reviewerUser?.firstName.localeCompare(
          b.dataLog?.reviewerUser?.firstName
        ),
      sortIcon: renderSortIcon,
      render: (_, record: MappedReturn) => {
        if (
          currentUser &&
          AccountType[currentUser.accountType] === AccountType.CLIENT
        )
          return;
        if (record.dataLog?.reviewerUser) {
          const userId = record.dataLog.reviewerUser.userUuid;
          const firstName = record.dataLog.reviewerUser?.firstName;
          const lastName = record.dataLog.reviewerUser?.lastName;
          const userPic = record.dataLog.reviewerUser?.profilePicture;
          return renderAvatar(
            firstName,
            lastName,
            AvatarSizes.DEFAULT,
            userId,
            userPic
          );
        }
      },
      responsive: ['lg'],
    },
    {
      title: t('main.client'),
      dataIndex: 'client',
      key: 'client',
      sorter: (a, b) =>
        a.dataLog?.clientUser?.firstName.localeCompare(
          b.dataLog?.clientUser?.firstName
        ),
      sortIcon: renderSortIcon,
      render: (_, record: MappedReturn) => {
        if (record.dataLog?.clientUser) {
          const userId = record.dataLog.clientUser.userUuid;
          const firstName = record.dataLog.clientUser?.firstName;
          const lastName = record.dataLog.clientUser?.lastName;
          const userPic = record.dataLog.clientUser.profilePicture;
          return renderAvatar(
            firstName,
            lastName,
            AvatarSizes.DEFAULT,
            userId,
            userPic
          );
        }
      },
      responsive: ['lg'],
    },
    {
      title: t('main.status'),
      dataIndex: 'status',
      key: 'status',
      render: (content) => <StatusGetter status={content} noDraft />,
      width: '200PX',
      sorter: (a, b) => a.status?.localeCompare(b.status),
      sortIcon: renderSortIcon,
    },
    {
      title: t('main.action-required'),
      render: (_, record: MappedReturn) => {
        const onClick = () => {
          vatGenerate(
            record.connectionId,
            record.mappingId,
            record.dataLog?.uuid
          );
        };
        return (
          record.mappingId &&
          checkForClient(record) && (
            <>
              <Space>
                {checkReconnectedBtn(record) ? (
                  <IconButton
                    type="default"
                    style={{
                      height: '25px',
                      minWidth: '88.2px',
                      borderRadius: 10,
                      display: 'flex',
                      alignItems: 'center',
                      padding: '14px 8px',
                    }}
                    icon={<RefreshIcon width={'18px'} height={'18px'} />}
                    onClick={connectEntity}
                    disabled={
                      (!record.dataLog &&
                        !record.connectedProperly &&
                        gettingTaxes) ||
                      !currentUser?.canManageOrgs
                    }
                    loading={
                      !record.dataLog &&
                      !record.connectedProperly &&
                      gettingTaxes
                    }
                  >
                    {t('main.reconnect')}
                  </IconButton>
                ) : (
                  <ScopeButton //@ts-ignore
                    width="107px"
                    height="25px"
                    fontSize="12px"
                    lineheight="12px"
                    onClick={onClick}
                    type="primary"
                    //disabled={checkLoading(record, DISABLE)}
                    //@ts-ignore
                    loading={checkLoading(record, LOADING)}
                  >
                    {checkLoading(record, BUTTON_TEXT)}
                  </ScopeButton>
                )}

                {currentUser?.accountType &&
                  AccountType[currentUser.accountType] !==
                    AccountType.CLIENT && (
                    <Popover
                      placement="topLeft"
                      arrow={false}
                      trigger="click"
                      content={renderContextMenu(record)}
                      title={''}
                      overlayStyle={{ zIndex: 999 }}
                    >
                      <ContextButton type="text">
                        <EllipseIcon
                          style={{
                            height: '10px',
                          }}
                        />
                      </ContextButton>
                    </Popover>
                  )}
              </Space>
            </>
          )
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: '40px',
        paddingTop: '10px',
        maxWidth: 1600,
        minWidth: 1320,
        margin: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '25px',
        }}
      >
        <DashboardContainer
          className={
            selectedContanier == dashboardStatistic.taxReturns
              ? 'selected-container'
              : 'non-selected-container'
          }
          onClick={() => filterDueReturns('tax-returns')}
        >
          <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
            <ConnectIcon className="container-icon" />
            <Text className="container-title">{t('main.tax-returns')}</Text>
            <Text className="container-number">{mappedReturns?.length}</Text>
          </Space>
        </DashboardContainer>
        <DashboardContainer
          className={
            selectedContanier == dashboardStatistic.dueReturns
              ? 'selected-container'
              : 'non-selected-container'
          }
          onClick={() => filterDueReturns('due-returns')}
        >
          <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
            <ClockIcon className="container-icon" />
            <Text className="container-title">{t('main.due-returns')}</Text>
            <Text className="container-number">{calcDueReturns()}</Text>
          </Space>
        </DashboardContainer>
        <DashboardContainer
          className={
            selectedContanier == dashboardStatistic.expiredReturns
              ? 'selected-container'
              : 'non-selected-container'
          }
          onClick={() => filterDueReturns('expired-returns')}
        >
          <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
            <WarningIcon className="container-icon" />
            <Text className="container-title">{t('main.expired-returns')}</Text>
            <Text className="container-number">{calcExpiredReturns()}</Text>
          </Space>
        </DashboardContainer>
        <DashboardContainer
          className={
            selectedContanier == dashboardStatistic.awaitingClient
              ? 'selected-container'
              : 'non-selected-container'
          }
          onClick={() => filterDueReturns('awaiting-client')}
        >
          <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
            <UserIcon className="container-icon" />
            <Text className="container-title">{t('main.awaiting-client')}</Text>
            <Text className="container-number">
              {calcAwaitingClientReturns()}
            </Text>
          </Space>
        </DashboardContainer>
      </div>
      <Spin spinning={loading} size="large">
        {mappedReturns && mappedReturns.length > 0 ? ( //@ts-ignore
          <ScopeTable
            filters={[
              'mappingName',
              'organisation',
              'nextDueDate',
              'preparer',
              'reviewer',
              'client',
              'status',
            ]} //@ts-ignore
            originalDataSource={mappedReturns} //@ts-ignore
            tableDataSource={dataSource} //@ts-ignore
            columns={columns}
            pagination={true}
            rowsPerPage={10}
            enableColumnsCustomization={false}
            updateDueDateFilter={updateDueDateFilter}
            setUpdateDueDateFilter={setUpdateDueDateFilter}
            updateStatusFilter={updateStatusFilter}
            setUpdateStatusFilter={setUpdateStatusFilter}
            setSelectedContanier={setSelectedContanier}
            selectedContanier={selectedContanier}
            haveBorder
          />
        ) : (
          <EmptyPage
            titleText={
              hasEntities ? 'No Tax Returns found' : 'No Entities found'
            }
            actionText={
              hasEntities
                ? 'Click on the button below to proceed'
                : !currentUser?.canManageOrgs
                ? 'Contact the account owner to give you access to an Entity'
                : 'Click on the button below to get started'
            }
            buttonText={hasEntities ? 'Continue' : 'Add an entity'}
            actionCallback={emptyPageCallback}
            disableButton={!currentUser?.canManageOrgs}
          />
        )}
      </Spin>
    </div>
  );
};
