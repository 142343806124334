import { Form } from 'antd';
import { UsersComponent } from 'components/UsersComponent';
import { AddMemberForm } from 'components/UsersComponent/add-member';
import { History } from 'components/UsersComponent/history';
import { Rights } from 'components/UsersComponent/rights';
import { push } from 'connected-react-router';
import { MessageStates } from 'containers/MessageBox';
import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes';
import { PermissionType, TablePermission } from 'store/api/enums';
import {
  turnDrawerOn,
  turnDrawerOff,
  turnMessageOn,
  turnModalOff,
  turnModalOn,
} from 'store/app/actions';
import { DrawerType, CurrentModal } from 'store/app/enums';
import { getModalOpen } from 'store/app/selectors';
import { DrawerData, MessageData, ModalData } from 'store/app/types';
import { getAuthUser } from 'store/auth/selectors';
import { resendActivationRequest } from 'store/signup/actions';
import {
  deleteUserRequest,
  addMemberRequest,
  fetchUsersRequest,
  setUserPermissionsRequest,
  fetchUserHistoryRequest,
  fetchUserRightsRequest,
  setUserRightsRequest,
  setAllUserRightsRequest,
} from 'store/user/actions';
import {
  getUserHistory,
  getUserLoading,
  getUserMembers,
  getUserRights,
} from 'store/user/selectors';
import { getOrgMappedOrganisations } from 'store/organisation/selectors';
import { UserRights } from 'store/user/models';
import { fetchProfilePictureRequest } from 'store/account/actions';
import { checkPageLoaded } from 'utils/check-page-loaded';
import { useLocation } from 'react-router';

export interface NewMember {
  firstName: string;
  lastName: string;
  email: string;
  isClient: boolean;
}

export const UsersPage: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getUserLoading);
  const members = useSelector(getUserMembers);
  const currentUser = useSelector(getAuthUser);
  const history = useSelector(getUserHistory);
  const rights = useSelector(getUserRights);
  const modalOpen = useSelector(getModalOpen);
  const mappedOrganisations = useSelector(getOrgMappedOrganisations);
  const location = useLocation();
  const [form] = Form.useForm();
  const [currentModal, setCurrentModal] = React.useState<CurrentModal>(
    CurrentModal.NONE
  );
  const [userName, setUserName] = React.useState('');
  const { t } = useTranslation();

  const goBack = () => {
    dispatch(push(routes.main));
  };

  const openMemberWindow = () => {
    const modalData: DrawerData = {
      type: DrawerType.ADD_MEMBER,
      title: t('users.add-a-user'),
      component: <AddMemberForm saveNewMember={saveNewMember} />,
    };

    dispatch(turnDrawerOn(modalData));
  };

  const openHistoryWindow = (userId: string, userName: string) => {
    setUserName(userName);
    currentUser && dispatch(fetchUserHistoryRequest(userId));
    setCurrentModal(CurrentModal.HISTORY);
  };

  const openRightsWindow = (accountId: string, userName: string) => {
    setUserName(userName);
    dispatch(fetchUserRightsRequest(accountId));
    setCurrentModal(CurrentModal.RIGHTS);
  };

  React.useEffect(() => {
    if (currentModal === CurrentModal.HISTORY) {
      const modalData: DrawerData = {
        type: DrawerType.HISTORY,
        title: `${t('users.login-history')}`,
        component: !loading && history && (
          <History
            history={history}
            onClose={() => dispatch(turnDrawerOff())}
          />
        ),
      };

      dispatch(turnDrawerOn(modalData));
    }

    if (currentModal === CurrentModal.RIGHTS) {
      const modalData: DrawerData = {
        type: DrawerType.RIGHTS,
        title: `${t('users.access-rights')}`,
        component: !loading && rights && (
          <Rights
            loading={loading}
            rights={rights}
            saveUserRights={saveUserRights}
            saveAllUserRights={saveAllUserRights}
            entities={mappedOrganisations}
            onClose={() => dispatch(turnDrawerOff())}
          />
        ),
      };

      dispatch(turnDrawerOn(modalData));
    }
  }, [history, rights]);

  React.useEffect(() => {
    modalOpen && form.resetFields();
  }, [modalOpen]);

  const saveNewMember = (newMember: NewMember) => {
    dispatch(addMemberRequest(newMember));
    dispatch(turnDrawerOff());
  };

  const deleteUser = (userToDeleteId: string) => {
    currentUser &&
      userToDeleteId &&
      dispatch(deleteUserRequest(userToDeleteId));
  };

  const resendActivation = (email: string) => {
    if (email) {
      const message: MessageData = {
        title: t('common.sending-request'),
        description: t('common.be-patient'),
        type: MessageStates.INFO,
        duration: 2,
      };
      dispatch(turnMessageOn(message));
      dispatch(resendActivationRequest(email, true));
    }
  };

  React.useEffect(() => {
    setTimeout(function () {
      !members && dispatch(fetchUsersRequest());
    }, 1000);

    currentUser && checkPageLoaded(location.pathname, routes.users);
  }, []);

  const saveUserPermissions = (
    accountUuid: string,
    permissionType: PermissionType,
    enabled: boolean
  ) => {
    dispatch(setUserPermissionsRequest(accountUuid, permissionType, enabled));
  };

  const saveUserRights = (
    userRights: UserRights,
    tablePermission: TablePermission
  ) => {
    const accountUuid = userRights.accountUuid;
    const userToModify = members?.find((m) => m.accountUuid === accountUuid);
    const userToSend = userToModify ? userToModify : undefined;

    dispatch(setUserRightsRequest(userRights, tablePermission, userToSend));
  };

  const saveAllUserRights = (
    userRights: UserRights[],
    tablePermission: TablePermission
  ) => {
    const accountUuid = userRights[0].accountUuid;
    const userToModify = members?.find((m) => m.accountUuid === accountUuid);
    const userToSend = userToModify ? userToModify : undefined;

    dispatch(setAllUserRightsRequest(userRights, tablePermission, userToSend));
  };

  const getPicture = (userUuid: string) =>
    dispatch(fetchProfilePictureRequest(userUuid));
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Users | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for users" />
      </Helmet>
      {members && (
        <UsersComponent
          loading={loading}
          members={members}
          currentUser={currentUser}
          saveUserPermissions={saveUserPermissions}
          openMemberWindow={openMemberWindow}
          openHistoryWindow={openHistoryWindow}
          openRightsWindow={openRightsWindow}
          deleteUser={deleteUser}
          resendActivation={resendActivation}
          goBack={goBack}
          getPicture={getPicture}
        />
      )}
    </React.Fragment>
  );
};
