import { takeLatest } from 'redux-saga/effects';
import {
  ADD_PASSWORD_REQUEST,
  CHANGE_OWNER_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  DISABLE_MFA_REQUEST,
  DISCONNECT_PROVIDER_REQUEST,
  ENABLE_MFA_REQUEST,
  FETCH_CONNECT_URL_REQUEST,
  FETCH_PROFILE_PICTURE_REQUEST,
  FETCH_QR_CODE_REQUEST,
  UPDATE_DETAILS_REQUEST,
  UPLOAD_PROFILE_PICTURE_REQUEST,
} from '../constants';
import { fetchConnectUrlSaga } from './fetchConnectUrlSaga';
import { fetchQRCodeSaga } from './fetchQRCodeSaga';
import { disconnectAccountSaga } from './disconnectAccountSaga';
import { enableMfaSaga } from './enableMfaSaga';
import { disableMfaSaga } from './disableMfaSaga';
import { updateDetailsSaga } from './updateDetailsSaga';
import { changePasswordSaga } from './changePasswordSaga';
import { changeOwnerSaga } from './changeOwnerSaga';
import { deleteAccountSaga } from './deleteAccountSaga';
import { fetchProfilePictureSaga } from './fetchUserProfilePictureSaga';
import { uploadProfilePictureSaga } from './uploadProfilePictureSaga';
import { addPasswordSaga } from './addNewPasswordSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* accountSaga() {
  yield takeLatest(FETCH_CONNECT_URL_REQUEST, fetchConnectUrlSaga);
  yield takeLatest(DISCONNECT_PROVIDER_REQUEST, disconnectAccountSaga);
  yield takeLatest(FETCH_QR_CODE_REQUEST, fetchQRCodeSaga);
  yield takeLatest(ENABLE_MFA_REQUEST, enableMfaSaga);
  yield takeLatest(DISABLE_MFA_REQUEST, disableMfaSaga);
  yield takeLatest(UPDATE_DETAILS_REQUEST, updateDetailsSaga);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga);
  yield takeLatest(CHANGE_OWNER_REQUEST, changeOwnerSaga);
  yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccountSaga);
  yield takeLatest(FETCH_PROFILE_PICTURE_REQUEST, fetchProfilePictureSaga);
  yield takeLatest(UPLOAD_PROFILE_PICTURE_REQUEST, uploadProfilePictureSaga);
  yield takeLatest(ADD_PASSWORD_REQUEST, addPasswordSaga);
}
