import * as React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { getAuthUser } from 'store/auth/selectors';

export const NotFoundPage: React.FC = () => {
  const loggedUser = useSelector(getAuthUser);

  React.useEffect(() => {
    if (loggedUser) window.location.replace('/login');
    else {
      localStorage.removeItem('genTaxCalledPage');
      window.location.replace('/main');
    }
  }, [loggedUser]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Not Found | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for no results" />
      </Helmet>

      {/* <div className="page-404 px-4 p-md-0">Not Found Page</div> */}
    </React.Fragment>
  );
};
