import { ConnectEntity } from 'components/ConnectEntity';
import { MainComponent } from 'components/MainComponent';
import { push } from 'connected-react-router';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import { VatOverviewPage } from 'pages/vat';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import routes from 'routes';
import { turnDrawerOn, turnModalOn } from 'store/app/actions';
import { DrawerType, WindowType } from 'store/app/enums';
import { DrawerData, ModalData } from 'store/app/types';
import { getAuthUser } from 'store/auth/selectors';
import { getCommonCountries } from 'store/common/selectors';

import {
  getOrgGettingTaxes,
  getOrgLoading,
  getOrgMappedReturns,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import {
  deleteMappingRequest,
  fetchAddReturnDataRequest,
} from 'store/returns/actions';
import { ReturnData } from 'store/returns/models';
import { getReturnLoading } from 'store/returns/selectors';
import { getVatPrepLoading } from 'store/vat/selectors';
import { checkPageLoaded } from 'utils/check-page-loaded';

export const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const mappedReturns = useSelector(getOrgMappedReturns);
  const entities = useSelector(getOrgOrganisations);
  const gettingTaxes = useSelector(getOrgGettingTaxes);
  const countries = useSelector(getCommonCountries);
  const currentUser = useSelector(getAuthUser);
  const orgLoading = useSelector(getOrgLoading);
  const retLoading = useSelector(getReturnLoading);
  const vatLoading = useSelector(getVatPrepLoading);

  //const lastCalledValue = localStorage.getItem('genTaxCalledPage');
  //alert(lastCalledValue);
  const [showPage, setShowPage] = React.useState(false);

  const loading = orgLoading || retLoading || vatLoading;

  const addReturnMapping = (connectionId: string) => {
    if (connectionId) {
      dispatch(push(routes.returns.add.replace(':connectionId', connectionId)));
    } else alert('Missing connection Id.');
  };

  const emptyPageCallback = () => {
    if (!!entities && entities.length > 0) {
      dispatch(push(routes.organisations.all));
    } else {
      const drawerData: DrawerData = {
        title: 'Add a new entity',
        subtitle: 'Connect one of the below',
        type: DrawerType.CONNECT_ENTITY,
        component: <ConnectEntity />,
      };
      dispatch(turnDrawerOn(drawerData));
    }
  };

  const editReturnMapping = (connectionId: string, mappingId: string) => {
    // const selectedOrg = mappedReturns?.find((mr) => mr.mappingId === mappingId);
    // const returnTypes = selectedOrg?.returnTypes;
    // const returnTypesEdit = selectedOrg?.returnTypesEdit;
    // const taxRates = selectedOrg?.taxRates;

    // const sections = selectedOrg?.sections ? selectedOrg.sections : [];
    // const retData: ReturnData = {
    //   returnTypes,
    //   returnTypesEdit,
    //   sections,
    //   connectionId,
    //   taxRates,
    // };

    // dispatch(fetchAddReturnDataRequest(connectionId, retData));
    if (connectionId && mappingId) {
      dispatch(
        push(
          routes.returns.edit
            .replace(':connectionId', connectionId)
            .replace(':mappingId', mappingId)
        )
      );
    } else alert('Missing connection Id.');
  };

  const deleteReturnMapping = (organisationId: string, mappingId: string) => {
    if (organisationId && mappingId) {
      dispatch(deleteMappingRequest(organisationId, mappingId));
    } else alert('Missing connection / mapping Id.');
  };

  const vatGenerate = (
    connectionId: string,
    mappingId: string,
    dataLogId: string
  ) => {
    const calledConfirmed = () =>
      vatGenerate2(connectionId, mappingId, dataLogId);
    const description = (
      <div>
        The way <strong>Output Tax</strong> and <strong>Input Tax</strong> is
        calculated has changed. Previously, it was system-generated. However,
        we've now introduced an improved Reverse charge mechanism.
        <br />
        <br /> Before creating a new tax submission, please go to the mapping
        page and edit the mapping for output and input tax boxes for this entity
        (You will need to cancel any open tax submissions before editing the
        mapping). <br />
        <br />
        Refer to the What's New page for more information about this new
        feature.{' '}
        <strong>
          {' '}
          You will also need to specify which tax rates are of type Input and
          output.
        </strong>
      </div>
    );

    const modalData: ModalData = {
      type: WindowType.WARNING,
      title: 'System Message',
      component: (
        <ConfirmBoxContainer
          callback={calledConfirmed}
          showCancelBtn={true}
          okText="Continue"
          description={description}
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const vatGenerate2 = (
    connectionId: string,
    mappingId: string,
    dataLogId: string
  ) => {
    dataLogId
      ? dispatch(
          push(
            routes.vat.viewReportLink
              .replace(':connectionId', connectionId)
              .replace(':mappingId', mappingId)
              .replace(':dataLogId', dataLogId)
          )
        )
      : openVatWindow(connectionId, mappingId);
  };

  const openVatWindow = (
    connectionId: string,
    mappingId: string
    //dataLogId?: string
  ) => {
    const modalData: DrawerData = {
      type: DrawerType.ADD_TAX_SUBMISSION,
      title: 'Create Tax Submission',
      component: (
        <VatOverviewPage connectionId={connectionId} mappingId={mappingId} />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const onAddEntity = () => {
    const drawerData: DrawerData = {
      title: 'Connect entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  React.useEffect(() => {
    setShowPage(true);
    !currentUser && window.location.replace('/login');
    currentUser && checkPageLoaded(location.pathname, routes.main);
  }, []);

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Home | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This a home page" />
          </Helmet>

          <MainComponent
            loading={loading}
            mappedReturns={mappedReturns}
            countries={countries}
            addReturnMapping={addReturnMapping}
            editReturnMapping={editReturnMapping}
            deleteReturnMapping={deleteReturnMapping}
            vatGenerate={vatGenerate}
            currentUser={currentUser}
            emptyPageCallback={emptyPageCallback}
            hasEntities={!!entities && entities.length > 0}
            gettingTaxes={gettingTaxes}
            connectEntity={onAddEntity}
          />
        </>
      )}
    </React.Fragment>
  );
};
