import { api } from 'store/api';
import { ApiResponse } from 'store/api/types';
import { Provider } from 'store/api/enums';
import { User } from 'store/auth/models';

export interface FetchConnectUrlResponse extends ApiResponse {
  data: { url: string };
}
export const getConnectAccountUrl = (
  provider: Provider,
  action: string
): Promise<FetchConnectUrlResponse> =>
  api.get<FetchConnectUrlResponse>(
    `/auth/account/signInWithProvider/${provider}/${action}`
  );

export const disconnectProvider = (provider: Provider): Promise<ApiResponse> =>
  api.post<ApiResponse>(`/auth/account/disconnectFromProvider/${provider}`);

export interface FetchQRCodeResponse extends ApiResponse {
  data: { qrCodeImage: string; secretKey: string };
}
export const getQRCode = (): Promise<FetchQRCodeResponse> =>
  api.get<FetchQRCodeResponse>('/auth/account/mfa/getQRCode');

export const enableMfa = (
  code: string,
  secretKey: string
): Promise<ApiResponse> =>
  api.get<ApiResponse>(
    `/auth/account/mfa/enable?code=${code}&secretKey=${secretKey}`
  );

export const disableMfa = (): Promise<ApiResponse> =>
  api.post<ApiResponse>('/auth/account/mfa/disable');

export const updateDetails = (user: User): Promise<ApiResponse> =>
  api.put<ApiResponse>('/auth/account/details/update', { body: { ...user } });

export const changePassword = (
  oldPassword: string,
  newPassword: string
): Promise<ApiResponse> =>
  api.put<ApiResponse>('/auth/account/password/update', {
    body: { oldPassword, newPassword },
  });

export const changeOwner = (accountId: string): Promise<ApiResponse> =>
  api.put<ApiResponse>('/auth/account/owner/update', {
    body: { accountId },
  });

export const deleteAccount = (): Promise<ApiResponse> =>
  api.post<ApiResponse>('/auth/account/delete');

export const getProfilePicture = (userId: string): Promise<any> =>
  api.get<any>(`/file/profile-picture/${userId}`);

export const uploadProfilePicture = (
  img: File
): Promise<any> => {
  const data = new FormData();
  data.append('file', img);
  return api.post<ApiResponse>('/file/upload/profile-picture', {
    body: data,
    formData:true
  });
};

export const addPassword = (
  newPassword: string
): Promise<ApiResponse> =>
  api.put<ApiResponse>('/auth/account/password/new', {
    body: { newPassword },
  });
