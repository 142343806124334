import { ContactDetails } from 'components/VatChecker/CustomersAndSuppliers/contact-details';
import { ViesLogs } from 'components/VatChecker/CustomersAndSuppliers/vies-logs';
import { DownloadCertificate } from 'components/VatChecker/download-Certificate';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import routes from 'routes';
import {
  turnDrawerOff,
  turnDrawerOn,
  turnModalOff,
  turnModalOn,
} from 'store/app/actions';
import { DrawerType, WindowType } from 'store/app/enums';
import { DrawerData, ModalData } from 'store/app/types';
import { getAuthUser } from 'store/auth/selectors';
import { fetchOrganisationsRequest } from 'store/organisation/actions';
import { Organisation } from 'store/organisation/models';
import { getOrgOrganisations } from 'store/organisation/selectors';
import { fetchUsersRequest } from 'store/user/actions';
import { getUserMembers } from 'store/user/selectors';
import {
  checkContactRequest,
  fetchContactRequest,
  fetchContactsRequest,
  refreshContactRequest,
} from 'store/vat-checker/actions';
import { Contact } from 'store/vat-checker/models';
import {
  getViesChecks,
  getViesContacts,
  getViesLoading,
} from 'store/vat-checker/selectors';
import { checkPageLoaded } from 'utils/check-page-loaded';

export const ContactPage: React.FC = () => {
  const parameters: any = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(getAuthUser);
  const contacts = useSelector(getViesContacts);
  const orgs = useSelector(getOrgOrganisations);
  const members = useSelector(getUserMembers);
  const viesLogs = useSelector(getViesChecks);
  const loading = useSelector(getViesLoading);
  const [selectedContact, setSelectedContact] = React.useState<Contact>();
  const [selectedOrg, setSelectedOrg] = React.useState<Organisation>();
  const [gettingLogs, setGettingLogs] = React.useState<string>();
  const { t } = useTranslation();

  React.useEffect(() => {
    !contacts && dispatch(fetchContactsRequest(parameters.connectionId));

    !orgs && dispatch(fetchOrganisationsRequest());

    !members && dispatch(fetchUsersRequest());
    currentUser &&
      checkPageLoaded(location.pathname, routes.vies.qualifiedEntities);
  }, []);

  React.useEffect(() => {
    if (contacts) {
      const contact = contacts.find(
        (o) => o.contactUuid === parameters.contactId
      );
      contact && dispatch(fetchContactRequest(contact?.contactUuid));
      setSelectedContact(contact);
    }

    if (orgs) {
      const currentOrg = orgs?.find(
        (org) => org.connectionId == parameters.connectionId
      );
      setSelectedOrg(currentOrg);
    }
  }, [contacts, orgs]);

  const closeDrawer = () => dispatch(turnDrawerOff());

  React.useEffect(() => {
    if (gettingLogs && viesLogs) {
      const drawerData: DrawerData = {
        title: gettingLogs,
        type: DrawerType.VIES_LOGS,
        component: (
          <ViesLogs
            viesLogs={viesLogs}
            currentContact={selectedContact}
            openDownloadCertificateModal={openDownloadCertificateModal}
            providerName={selectedOrg?.providerName}
            closeDrawer={closeDrawer}
          />
        ),
      };

      dispatch(turnDrawerOn(drawerData));
      setGettingLogs(undefined);
    }
  }, [viesLogs]);

  const handleRefreshData = (contactUuid: string) => {
    selectedOrg &&
      dispatch(refreshContactRequest(contactUuid, selectedOrg?.connectionId));
  };

  const checkContact = (contactId) => dispatch(checkContactRequest(contactId));

  const getHistoryLogs = (contactUuid: string, contactName: string) => {
    if (contactUuid) {
      setGettingLogs(contactName);
      dispatch(fetchContactRequest(contactUuid));
    }
  };

  const openDownloadCertificateModal = (contact: Contact, invoice: string) => {
    const modalData: ModalData = {
      title: '',
      type: WindowType.DownloadCertificate,
      component: (
        <DownloadCertificate
          contact={contact}
          invoice={invoice}
          onClose={() => turnModalOff()}
        />
      ),
    };
    dispatch(turnModalOn(modalData));
  };

  return (
    <>
      <Helmet>
        <title>{`${selectedContact?.name} | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a home page" />
      </Helmet>

      <ContactDetails
        loading={loading}
        contact={selectedContact}
        organisation={selectedOrg}
        viesLogs={viesLogs}
        handleRefreshData={handleRefreshData}
        checkContact={checkContact}
        getHistoryLogs={getHistoryLogs}
        openDownloadCertificateModal={openDownloadCertificateModal}
      />
    </>
  );
};
