//@ts-nocheck
import React from 'react';
import { Tooltip, TooltipProps } from 'antd';
import styled from 'styled-components';

interface CustomTooltipProps extends TooltipProps{
  className?: string;
  children: any;
  restProps: any;
}

const CustomTooltip = ({
  className,
  children,
  ...restProps
}: CustomTooltipProps) => (
  <Tooltip overlayClassName={className} {...restProps}>
    {children}
  </Tooltip>
);

export const StyledTooltip = styled(CustomTooltip)`
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    font-family: Inter !important;
    font-size: ${(props) =>
      props.fontSize ? props.fontSize : '13px'} !important;
    font-weight: 400 !important;
    background-color: white !important;
    color: #879494 !important;
    width: ${(props) =>
      props.width ? props.width : 'auto'} !important;
    border-radius: 15px;
    padding: 12px;
  }
`;
