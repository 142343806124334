import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getCommonCountries } from 'store/common/selectors';
import {
  Col,
  Row,
  Space,
  Select,
  Typography,
  Table,
  DatePicker,
  Checkbox,
  Divider,
  Badge,
  Popover,
} from 'antd';
import { LinkButton, ScopeButton, IconButton } from 'components/Custom/buttons';
import { FilterSelect, FormSelect } from 'components/Custom/selects';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DateOptions } from 'store/api/enums';
import * as dayjs from 'dayjs';
import 'dayjs/plugin/quarterOfYear';
import {
  DataLogStatus,
  VIESContactStatistic,
  ViesStatus,
  dashboardStatistic,
} from 'store/app/enums';
import { AccountType } from 'store/api/enums';
import { AvatarSizes, renderAvatar } from 'utils/render-avatar';
import * as _ from 'lodash';
import { usePrevious } from 'hooks/usePrevious';
import e from 'express';
import { SizeType } from 'antd/es/config-provider/SizeContext';

const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }

  .ant-table-thead {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    border-bottom: none;
  }

  .ant-table-thead > tr > th::before {
    width: 0px !important;
    background-color: white !important;
  }

  .ant-table-tbody > tr > td {
    height: ${(props) => (props.rowheight ? props.rowheight : '45')}px;
    padding: 4px 12px;
    border-bottom: none;
  }

  .ant-table-tbody > tr > td:first-child {
    border-end-start-radius: 10px;
    border-start-start-radius: 10px;
  }

  .ant-table-tbody > tr > td:last-child {
    border-start-end-radius: 10px;
    border-end-end-radius: 10px;
  }

  .ant-table-row,
  td.ant-table-column-sort {
    background-color: #f3f7f8;
    margin-bottom: 5px;
  }

  .ant-table-row:hover * a,
  .ant-table-row:hover * span[title] {
    text-decoration: underline;
  }

  .ant-table-row > .ant-table-cell-row-hover {
    background-color: #e6eff2 !important;
  }

  th.ant-table-column-sort,
  th.ant-table-column-has-sorters:hover {
    background-color: white !important;
  }

  .ant-table-column-title {
    padding-right: 5px;
    z-index: 0 !important;
  }

  .ant-table-column-sorters {
    width: fit-content !important;
  }

  .ant-table-column-sorters > .ant-table-column-title,
  .ant-table-cell,
  .ant-space-item {
    width: max-content;
  }

  .ant-table-cell {
    text-wrap: nowrap;
    font-size: 13px;
    font-weight: 400;
  }

  .context-menu-items {
    //width: 173px;
    max-height: 133px;
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 10px 10px 0px #0000000d;
    border: 1px solid #e9f6f7;
  }

  .ant-popover-inner {
    padding: 8px !important;
  }

  .ant-pagination-total-text {
    margin-top: 10px;
    flex: 1;
  }

  .expired-due-date {
    color: #d13131;
  }

  .ant-switch-inner {
    background: #dbeae3 !important;
  }

  .switch-checked {
    > div::before {
      background: #41aaaf !important;
    }
  }

  .ant-table-title {
    padding: ${(props) => (props.className == 'recap-statement' ? '0px 0px' : '16px 0px')} !important;
  }

  .no-padding {
    padding: 0 12px 0 0 !important;
  }
`;

interface ScopeTableProps {
  className?: string;
  filters: string[];
  tableDataSource?: any[];
  originalDataSource?: any[];
  columns?: any[];
  pagination: boolean;
  rowsPerPage: number;
  enableColumnsCustomization: boolean;
  updateDueDateFilter?: boolean;
  setSelectedContanier?: Dispatch<SetStateAction<string>>;
  selectedContanier?: string;
  setUpdateDueDateFilter?: Dispatch<SetStateAction<string>>;
  setUpdateStatusFilter?: Dispatch<SetStateAction<string>>;
  renderRightComponent?: React.JSX.Element | undefined;
  titleComponent?: React.JSX.Element | undefined;
  updateStatusFilter?: boolean;
  rowHeight: number;
  mainLocaleKey?: string;
  selectedFilter?: Record<string, string>;
  haveBorder?: boolean;
  statusFilterKey?: string;
  size?: SizeType;
}

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const CustomDateFormat = 'MM/DD/YYYY';
dayjs.extend(require('dayjs/plugin/quarterOfYear'));

const userNameFilters = [
  'accountCreatorName',
  'users',
  'preparer',
  'reviewer',
  'client',
];

const Unassigned = 'unassigned';

export const ScopeTable: React.FC<ScopeTableProps> = ({
  className,
  filters,
  tableDataSource,
  columns,
  pagination,
  rowsPerPage,
  enableColumnsCustomization,
  updateDueDateFilter,
  setSelectedContanier,
  selectedContanier,
  setUpdateDueDateFilter,
  setUpdateStatusFilter,
  updateStatusFilter,
  originalDataSource,
  rowHeight,
  mainLocaleKey,
  renderRightComponent,
  titleComponent,
  haveBorder,
  selectedFilter,
  statusFilterKey,
  size,
}) => {
  const { t } = useTranslation();
  const countries = useSelector(getCommonCountries);
  const [filtersState, setFiltersState] = useState({});
  const [dataSource, setDataSource] = useState(tableDataSource);
  const [openFilter, setOpenFilter] = useState({});
  const [enableCustomRange, setEnableCustomRange] = useState(false);
  const [unassignedFlag, setUnassignedFlag] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [dateRangeOption, setDateRangeOption] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [hoveredFilter, setHoveredFilter] = useState('');
  const [badgeCounts, setBadgeCounts] = useState({});
  const [searchValue, setSearchValue] = useState({});
  const { prev, current } = usePrevious(selectedFilters);
  const savedFiltersState = usePrevious(filtersState);

  useEffect(() => {
    setDataSource(tableDataSource);
  }, [tableDataSource]);

  useEffect(() => {
    if (selectedFilter?.value) {
      setFiltersState({
        ...filtersState,
        [selectedFilter.key]: {
          value: selectedFilter.value,
        },
      });

      setIsApplied(true);
      setSelectedFilters([selectedFilter.key]);
      setBadgeCounts({
        key: selectedFilter.key,
        value: 1,
      });
      //handleOnClickApply(selectedFilter.key, selectedFilter.key);
    } else if (selectedFilter && selectedFilters.length > 0) {
      setSelectedFilters([]);
      setFiltersState({});
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (
      selectedContanier == dashboardStatistic.taxReturns ||
      selectedContanier == dashboardStatistic.expiredReturns
    ) {
      setDataSource(tableDataSource);
      setSelectedFilters([]);
      setDateRangeOption([]);
    }

    if (
      selectedContanier != '' &&
      selectedContanier != dashboardStatistic.dueReturns &&
      selectedContanier != dashboardStatistic.awaitingClient
    ) {
      setFiltersState({});
    }
  }, [selectedContanier]);

  useEffect(() => {
    if (statusFilterKey && statusFilterKey != '') {
      if (statusFilterKey == VIESContactStatistic.StatusMissing) {
        setFiltersState({
          ...filtersState,
          viesStatus: {
            value: ['EMPTY'],
          },
        });
      } else {
        setFiltersState({
          ...filtersState,
          viesStatus: {
            value: [
              ViesStatus.ERROR.toUpperCase(),
              ViesStatus.INVALID.toUpperCase(),
            ],
          },
        });
      }
    } else {
      if (!selectedFilter?.value) {
        setFilterState('viesStatus', []);

        const filters = [...selectedFilters];
        const remainingFilters = filters.filter(
          (filter) => filter != 'viesStatus'
        );
        setSelectedFilters(remainingFilters);
      }
    }
  }, [statusFilterKey]);

  useEffect(() => {
    if (updateDueDateFilter) {
      setFiltersState({});
      setDateRangeOption(DateOptions.ALL);
      const selectedKey = filters.find((filter) =>
        filter?.toLocaleLowerCase()?.includes('date')
      );
      setSelectedFilters((selectedFiltersArr) => [
        ...selectedFiltersArr,
        selectedKey,
      ]);
    } else {
      setDateRangeOption([]);
      setDataSource(tableDataSource);
    }
  }, [updateDueDateFilter]);

  useEffect(() => {
    if (updateStatusFilter) {
      setFiltersState({});
      onFilterSelect('status', [DataLogStatus.AWAITING_CLIENT_APPROVAL], true);
    } else {
      setDataSource(tableDataSource);
      if (Object.keys(filtersState).includes('status')) {
        setFiltersState({
          ...filtersState,
          ['status']: {
            value: [],
          },
        });
      }
    }
  }, [updateStatusFilter]);

  useEffect(() => {
    if (selectedFilters.length > 0 && prev.toString() != current.toString()) {
      if (!updateStatusFilter && updateDueDateFilter) {
        const selectedFiltersState = [...selectedFilters];
        const newSelectedFilters = selectedFiltersState.filter((item) =>
          item?.toLocaleLowerCase()?.includes('date')
        );
        setSelectedFilters(newSelectedFilters);
      } else if (updateStatusFilter && !updateDueDateFilter) {
        const selectedFiltersState = [...selectedFilters];
        setSelectedFilters(
          selectedFiltersState.filter((item) =>
            item?.toLocaleLowerCase()?.includes('status')
          )
        );
      }
    }
  }, [selectedFilters, updateStatusFilter, updateDueDateFilter]);

  useEffect(() => {
    if (!updateStatusFilter && !updateDueDateFilter) {
      const selectedFiltersState = [...selectedFilters];
      const newSelectedFilters = selectedFiltersState.filter(
        (item) =>
          !item?.toLocaleLowerCase()?.includes('status') &&
          !item?.toLocaleLowerCase()?.includes('date')
      );
      setSelectedFilters(newSelectedFilters);
    }
  }, [updateStatusFilter, updateDueDateFilter]);

  useEffect(() => {
    if (
      filtersState != {} &&
      JSON.stringify(savedFiltersState.prev) !=
        JSON.stringify(savedFiltersState.current)
    ) {
      if (Object.keys(filtersState).length > 0) {
        Object.keys(filtersState).forEach((filterKey, index) => {
          const originalKey = filters.find((filter) =>
            filter.includes(filterKey)
          );
          filtersState[filterKey]?.value?.length > 0 &&
            isApplied &&
            onFilterSelect(
              originalKey ? originalKey : filterKey,
              unassignedFlag[originalKey]
                ? Unassigned
                : filtersState[filterKey]?.value,
              index == 0
            );

          setBadgeCounts({
            key: originalKey ? originalKey : filterKey,
            value:
              typeof filtersState[filterKey]?.value == 'object'
                ? filtersState[filterKey]?.value?.length
                : 1,
          });
        });
        setIsApplied(false);
      } else {
        setDataSource(originalDataSource);
      }

      if (selectedFilter?.value) {
        Object.keys(filtersState).forEach((filterKey) => {
          setBadgeCounts({
            key: filterKey,
            value:
              typeof filtersState[filterKey]?.value == 'object'
                ? filtersState[filterKey]?.value?.length
                : 1,
          });
        });
      }
    }
  }, [JSON.stringify(filtersState)]);

  const onUnassignedFilterSelect = (
    key: string,
    useOriginalDataSource = false
  ) => {
    const keys = key.split('.');
    const currentDataSource = useOriginalDataSource
      ? originalDataSource
      : dataSource;
    if (unassignedFlag[key] && Array.isArray(currentDataSource[0][keys[0]])) {
      const filteredData = currentDataSource?.filter((row) =>
        row[keys[0]].some((rowKey) =>
          keys[1] ? rowKey[keys[1]] == '' : rowKey == ''
        )
      );
      setDataSource(filteredData);
    } else {
      setDataSource(currentDataSource?.filter((row) => row[keys[0]] == ''));
    }
  };

  const onFilterSelect = (
    key: string,
    value,
    useOriginalDataSource = false
  ) => {
    const keys = key?.split('.');
    setFilterState(keys[0], value);
    if (value && value != []) {
      setSelectedFilters((selectedFiltersArr) => [
        ...selectedFiltersArr,
        keys[0],
      ]);
    }

    const currentDataSource = useOriginalDataSource
      ? originalDataSource
      : dataSource;

    if (!value || value == []) {
      setDataSource(currentDataSource);
      return;
    }

    if (
      currentDataSource?.length > 0 &&
      Array.isArray(currentDataSource[0][keys[0]]) &&
      userNameFilters.includes(keys[0])
    ) {
      const filteredData = currentDataSource?.filter((row) =>
        row[keys[0]].some((rowKey) =>
          keys[1]
            ? value == Unassigned
              ? rowKey[keys[1]] == ''
              : value?.includes(rowKey[keys[1]])
            : value == Unassigned
            ? rowKey['fullName'] == ''
            : value?.includes(`${rowKey['fullName']}`)
        )
      );
      setDataSource(filteredData);
    } else if (
      currentDataSource?.length > 0 &&
      Array.isArray(currentDataSource[0][keys[0]])
    ) {
      const filteredData = currentDataSource?.filter((row) =>
        row[keys[0]].some((rowKey) =>
          keys[1]
            ? value == Unassigned
              ? rowKey[keys[1]] == ''
              : value?.includes(rowKey[keys[1]])
            : value == Unassigned
            ? rowKey == ''
            : value?.includes(rowKey)
        )
      );
      setDataSource(filteredData);
    } else {
      if (value?.includes('false') || value?.includes('true')) {
        setDataSource(
          currentDataSource?.filter((row) =>
            value?.includes(row[keys[0]]?.toString())
          )
        );
      } else if (userNameFilters.includes(keys[0])) {
        setDataSource(
          currentDataSource?.filter((row) =>
            value == Unassigned
              ? row[keys[0]] == null || row[keys[0]] == undefined
              : row[keys[0]] &&
                (value?.includes(
                  `${row[keys[0]]['firstName']} ${row[keys[0]]['lastName']}`
                ) ||
                  value?.includes(row[keys[0]]))
          )
        );
      } else {
        setDataSource(
          currentDataSource?.filter((row) =>
            value == Unassigned
              ? row[keys[0]] == ''
              : value?.includes(row[keys[0]])
          )
        );
      }
    }

    if (
      mainLocaleKey != 'vat-checker' &&
      setSelectedContanier &&
      !updateStatusFilter
    ) {
      setSelectedContanier('');
    }

    if (
      mainLocaleKey != 'vat-checker' &&
      setSelectedContanier &&
      updateStatusFilter &&
      updateDueDateFilter
    ) {
      setSelectedContanier('');
    }

    if (
      setSelectedContanier &&
      key == 'status' &&
      value == DataLogStatus.AWAITING_CLIENT_APPROVAL
    ) {
      setSelectedContanier(dashboardStatistic.awaitingClient);
    }
  };

  const clearFilters = () => {
    setFiltersState({});
    setDataSource(originalDataSource);

    setSelectedFilters([]);
    setDateRangeOption([]);
    setUnassignedFlag({});
    setSelectedContanier('');
  };

  const setFilterState = (key: string, value) => {
    if (typeof value == 'object' && value.length == 0) {
      const filterObj = { ...filtersState };
      delete filterObj[key];
      setFiltersState(filterObj);
      const filters = [...selectedFilters];
      const remainingFilters = filters.filter((filter) => filter != key);
      setSelectedFilters(remainingFilters);
    } else {
      setFiltersState({
        ...filtersState,
        [key]: {
          value: value,
        },
      });
    }
  };

  const removeDuplicate = (key: string) => {
    const keys = key.split('.');
    if (keys[0] == 'country' && mainLocaleKey != 'vat-checker') {
      const countryList = Array.from(
        new Set(dataSource?.map((taxReturn) => taxReturn[keys[0]]))
      );
      if (searchValue?.key == keys[0] && searchValue?.value != '') {
        return countryList.map((countryISO) =>
          countries.find(
            (c) =>
              c.iso === countryISO &&
              c.name.toLowerCase().includes(searchValue?.value.toLowerCase())
          )
        );
      } else {
        return countryList.map((countryISO) =>
          countries.find((c) => c.iso === countryISO)
        );
      }
    } else if (keys[0] == 'accountType') {
      const enumList = Array.from(
        new Set(dataSource?.map((taxReturn) => taxReturn[keys[0]]))
      );
      return enumList.map((item) => {
        if (searchValue?.key == keys[0] && searchValue?.value != '') {
          if (
            (mainLocaleKey == 'transactions' &&
              item
                ?.toLowerCase()
                ?.includes(searchValue?.value.toLowerCase())) ||
            AccountType[item]
              ?.toLowerCase()
              ?.includes(searchValue?.value.toLowerCase())
          ) {
            return { name: item ? AccountType[item] : item, value: item };
          }
        } else {
          return { name: item ? AccountType[item] : item, value: item };
        }
      });
    } else if (keys[0] == 'viesStatus') {
      const enumList = Array.from(
        new Set(dataSource?.map((taxReturn) => taxReturn[keys[0]]))
      );
      return enumList.map((item) => {
        if (searchValue?.key == keys[0] && searchValue?.value != '') {
          if (
            ViesStatus[item]
              .toLowerCase()
              ?.includes(searchValue?.value.toLowerCase())
          ) {
            return { name: item ? ViesStatus[item] : item, value: item };
          }
        } else {
          return { name: item ? ViesStatus[item] : item, value: item };
        }
      });
    } else if (
      dataSource?.length > 0 &&
      Array.isArray(dataSource[0][keys[0]])
    ) {
      const array = [];
      dataSource?.forEach((taxReturn) =>
        taxReturn[keys[0]].forEach((keyItem) =>
          keys[1] ? array.push(keyItem[keys[1]]) : array.push(keyItem)
        )
      );

      if (userNameFilters.includes(key)) {
        if (searchValue?.key == keys[0] && searchValue?.value != '') {
          return _.uniqBy(
            array.filter(
              (item) =>
                item != undefined &&
                (item.firstName + ' ' + item.lastName)
                  .toLowerCase()
                  .includes(searchValue?.value.toLowerCase())
            ),
            'userUuid'
          );
        } else {
          return _.uniqBy(
            array.filter((item) => item != undefined),
            'userUuid'
          );
        }
      } else {
        if (searchValue?.key == keys[0] && searchValue?.value != '') {
          return _.uniq(
            array.filter(
              (item) =>
                item != undefined &&
                item.toLowerCase().includes(searchValue?.value.toLowerCase())
            )
          );
        } else {
          return _.uniq(array.filter((item) => item != undefined));
        }
      }
    } else if (
      dataSource?.length > 0 &&
      userNameFilters.includes(keys[0]) &&
      _.some(dataSource, (item) => typeof item[keys[0]] == 'object')
    ) {
      const distinctArray = Array.from(
        new Set(dataSource?.map((taxReturn) => taxReturn[keys[0]]))
      );
      if (searchValue?.key == keys[0] && searchValue?.value != '') {
        return _.uniqBy(
          distinctArray.filter(
            (item) =>
              item != undefined &&
              (item.firstName + ' ' + item.lastName)
                .toLowerCase()
                .includes(searchValue?.value.toLowerCase())
          ),
          'userUuid'
        );
      } else {
        return _.uniqBy(
          distinctArray.filter((item) => item != undefined),
          'userUuid'
        );
      }
    } else {
      const distinctArray = Array.from(
        new Set(dataSource?.map((taxReturn) => taxReturn[keys[0]]))
      );
      if (searchValue?.key == keys[0] && searchValue?.value != '') {
        return distinctArray.filter(
          (item) =>
            item != undefined &&
            item?.toLowerCase()?.includes(searchValue?.value.toLowerCase())
        );
      } else {
        return distinctArray.filter((item) => item != undefined);
      }
    }
  };

  const renderAvatarOption = (names: string[], key, value) => {
    if (typeof value == 'object') {
      const userId = value?.userUuid;
      const firstName = value?.firstName;
      const lastName = value?.lastName;
      const userPic = value?.profilePicture;

      return (
        <Select.Option
          key={userId}
          value={firstName + ' ' + lastName}
          disabled={unassignedFlag[key] && userNameFilters.includes(key)}
        >
          <div style={{ display: 'flex' }}>
            {renderAvatar(
              firstName,
              lastName,
              AvatarSizes.DEFAULT,
              userId,
              userPic
            )}
            <div style={{ margin: '5px 12px' }}>
              {firstName + ' ' + lastName}
            </div>
          </div>
        </Select.Option>
      );
    } else {
      return (
        <Select.Option
          key={value}
          value={value}
          disabled={unassignedFlag[key] && userNameFilters.includes(key)}
        >
          <div style={{ display: 'flex' }}>
            {renderAvatar(names[0], names[1], AvatarSizes.DEFAULT, value)}
            <div style={{ margin: '5px 12px' }}>{value}</div>
          </div>
        </Select.Option>
      );
    }
  };

  const renderFilterOptions = (key: string) => {
    const keys = key.split('.');
    const selectOptions = removeDuplicate(key);
    const selectedOptions = selectOptions.filter((item) => item != undefined);

    return selectedOptions.length > 0
      ? selectedOptions?.map((option) => {
          let names = keys[1] ? option[keys[1]]?.split(' ') : '';
          if (
            key === 'country' &&
            mainLocaleKey != 'vat-checker' &&
            option &&
            option != ''
          ) {
            return (
              <Select.Option
                key={option['iso']}
                value={option['iso']}
                title={option['name']}
                label={
                  mainLocaleKey
                    ? t(`${mainLocaleKey}.${key}`)
                    : t(`main.${key}`)
                }
              >
                {option['name']}
              </Select.Option>
            );
          } else if (typeof option == 'boolean') {
            let optionTextVal = '';
            if (option) {
              optionTextVal = t('common.active');
            } else {
              optionTextVal = t('common.not-active');
            }
            return <Select.Option key={option}>{optionTextVal}</Select.Option>;
          } else if (
            typeof option == 'object' &&
            Object.keys(option).includes(keys[1])
          ) {
            return !userNameFilters.includes(key) ? (
              <Select.Option
                key={option[keys[1]]}
                value={option[keys[1]]}
                disabled={unassignedFlag[key] && userNameFilters.includes(key)}
              >
                {option[keys[1]]}
              </Select.Option>
            ) : (
              renderAvatarOption(names, key, option[keys[1]])
            );
          } else if (
            typeof option == 'object' &&
            !Object.keys(option).includes(keys[1])
          ) {
            return !userNameFilters.includes(key) ? (
              <Select.Option
                key={option['name']}
                value={option['value']}
                disabled={unassignedFlag[key] && userNameFilters.includes(key)}
              >
                {option['name']}
              </Select.Option>
            ) : (
              renderAvatarOption(names, key, option)
            );
          } else if (option && option != '') {
            names = option?.split(' ');
            return !userNameFilters.includes(key) ? (
              <Select.Option
                key={option}
                value={option}
                disabled={unassignedFlag[key] && userNameFilters.includes(key)}
              >
                {option}
              </Select.Option>
            ) : (
              renderAvatarOption(names, key, option)
            );
          }
        })
      : '';
  };

  const renderPaginationText = (total, range) => (
    <Typography
      style={{
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '12px',
        textAlign: 'left',
        color: '#042A2A',
      }}
    >
      {`${t('main.viewing')} ${range[0]}-${range[1]} ${t(
        'main.of'
      )} ${total} ${t('main.results')}`}
    </Typography>
  );

  const renderPaginationButtons = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <ScopeButton
          width="88px"
          height="25px"
          fontSize="12px"
          lineheight="12px"
          onClick={() => {}}
          color={'#879494'}
          type="default"
        >
          {t('main.previous')}
        </ScopeButton>
      );
    }
    if (type === 'next') {
      return (
        <ScopeButton
          width="75px"
          height="25px"
          fontSize="12px"
          lineheight="12px"
          onClick={() => {}}
          color={'#41AAAF'}
          type="default"
        >
          {t('main.next')}
        </ScopeButton>
      );
    }
    return null;
  };

  const onCustomRangeCheckboxChange = (e) => {
    if (e.target.checked) {
      setEnableCustomRange(true);
      setDateRangeOption(t('main.custom-range'));
    } else {
      setEnableCustomRange(false);
      setDateRangeOption([]);
    }
  };

  const onUnassignedCheckboxChange = (e, originalFilterKey) => {
    setUnassignedFlag({
      ...unassignedFlag,
      [originalFilterKey]: e.target.checked,
    });
  };

  const onDateFilterChange = (filterKey: string) => {
    let filteredData = [];
    if (dateRange[0] == '') {
      filteredData = originalDataSource?.filter(
        (item) => new Date(item[filterKey]).valueOf() > new Date().valueOf()
      );
      setSelectedContanier(dashboardStatistic.dueReturns);
    } else {
      if (dateRange?.length > 0) {
        const fromDate = dateRange[0].format(CustomDateFormat);
        const toDate = dateRange[1].format(CustomDateFormat);
        filteredData = dataSource.filter(
          (item) =>
            new Date(fromDate).valueOf() <=
              new Date(item[filterKey]).valueOf() &&
            new Date(item[filterKey]).valueOf() <= new Date(toDate).valueOf()
        );
        setSelectedContanier && setSelectedContanier('');
      }
    }
  
    if (dateRange && dateRange.length > 0) {
      setDataSource(filteredData);
      setSelectedFilters((selectedFiltersArr) => [
        ...selectedFiltersArr,
        filterKey,
      ]);
    }

    setOpenFilter({
      ...openFilter,
      [filterKey]: {
        value: false,
      },
    });

    if (setSelectedContanier && updateStatusFilter && updateDueDateFilter) {
      setSelectedContanier('');
    }
  };

  const onDateOptionSelect = (value) => {
    let date;
    const today = dayjs().format(dateFormat);
    let start = '';
    let end = '';
    switch (value) {
      case DateOptions.TODAY:
        date = [dayjs(today, dateFormat), dayjs(today, dateFormat)];
        break;
      case DateOptions.THIS_WEEK:
        start = dayjs().startOf('week').format(dateFormat);
        end = dayjs().endOf('week').format(dateFormat);
        date = [dayjs(start, dateFormat), dayjs(end, dateFormat)];
        break;
      case DateOptions.THIS_MONTH:
        start = dayjs().startOf('month').format(dateFormat);
        end = dayjs().endOf('month').format(dateFormat);
        date = [dayjs(start, dateFormat), dayjs(end, dateFormat)];
        break;
      case DateOptions.THIS_QUARTER:
        start = dayjs().startOf('quarter').format(dateFormat);
        end = dayjs().endOf('quarter').format(dateFormat);
        date = [dayjs(start, dateFormat), dayjs(end, dateFormat)];
        break;
      case DateOptions.NEXT_WEEK:
        start = dayjs().add(1, 'week').startOf('week').format(dateFormat);
        end = dayjs().add(1, 'week').endOf('week').format(dateFormat);
        date = [dayjs(start, dateFormat), dayjs(end, dateFormat)];
        break;
      case DateOptions.NEXT_MONTH:
        start = dayjs().add(1, 'month').startOf('month').format(dateFormat);
        end = dayjs().add(1, 'month').endOf('month').format(dateFormat);
        date = [dayjs(start, dateFormat), dayjs(end, dateFormat)];
        break;
      case DateOptions.NEXT_QUARTER:
        start = dayjs().add(1, 'quarter').startOf('quarter').format(dateFormat);
        end = dayjs().add(1, 'quarter').endOf('quarter').format(dateFormat);
        date = [dayjs(start, dateFormat), dayjs(end, dateFormat)];
        break;

      default:
        date = ['', ''];
        break;
    }
    setDateRange(date);
    setDateRangeOption(value);
  };

  const handleFilterChange = (key, value: string[]) => {
    setFilterState(key, value);
  };

  const handleOnClickDateCancel = (filterKey: string) => {
    setOpenFilter({
      ...openFilter,
      [filterKey]: {
        value: false,
      },
    });

    setSelectedContanier('');
    setDateRangeOption([]);
    const filters = [...selectedFilters];
    const remainingFilters = filters.filter((filter) => filter != filterKey);
    setSelectedFilters(remainingFilters);
    if (
      updateDueDateFilter &&
      selectedFilters.length == 1 &&
      selectedFilters.find(
        (filter) => filter.toLowerCase() == filterKey.toLowerCase()
      )
    ) {
      setDataSource(originalDataSource);
      setUpdateDueDateFilter(false);
    } else if (selectedFilters.length > 1) {
      setDataSource(dataSource);
    }

    if (
      selectedFilters?.filter(
        (filter) => filter.toLowerCase() != filterKey.toLowerCase()
      )?.length > 0
    ) {
      setDataSource(dataSource);
    } else {
      setDataSource(originalDataSource);
    }
  };

  const renderDateFilter = (filterKey: string) => {
    return (
      <Space
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        direction="vertical"
        style={{ padding: '20px' }}
      >
        <FormSelect
          width={'100%'}
          height={'35px'}
          radius={'10px'}
          isFilter={true}
          placeholder={getPlaceholder(filterKey)}
          style={{
            backgroundColor: '#F3F7F8',
            borderColor: '#cccccc',
            fontSize: 13,
          }}
          dropdownStyle={{ fontSize: 13 }}
          onSelect={(value) => onDateOptionSelect(value)}
          value={dateRangeOption}
        >
          {Object.values(DateOptions).map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </FormSelect>
        <Checkbox onChange={onCustomRangeCheckboxChange}>
          {t('main.custom-range')}
        </Checkbox>
        <RangePicker
          style={{ borderRadius: '10px', height: '34px' }}
          value={dateRange}
          disabled={!enableCustomRange}
          format={dateFormat}
          onChange={(date, dateString) => setDateRange(date)}
        />
        <Space style={{ float: 'inline-end', marginTop: '10px' }}>
          <ScopeButton
            width="75px"
            height="25px"
            fontSize="12px"
            lineheight="12px"
            style={{ float: 'right' }}
            onClick={() => handleOnClickDateCancel(filterKey)}
            color={'#41AAAF'}
            type="default"
          >
            {t('common.cancel')}
          </ScopeButton>
          <ScopeButton
            width="75px"
            height="25px"
            fontSize="12px"
            lineheight="12px"
            style={{ float: 'right' }}
            onClick={() => onDateFilterChange(filterKey)}
            type="primary"
          >
            {t('main.apply')}
          </ScopeButton>
        </Space>
      </Space>
    );
  };

  const handleOnClickFilterCancel = (
    filterKey: string,
    originalFilterKey: string
  ) => {
    setIsApplied(true);
    setFilterState(originalFilterKey.split('.')[0], []);
    const filters = [...selectedFilters];
    const remainingFilters = filters.filter(
      (filter) => filter != originalFilterKey.split('.')[0]
    );
    setSelectedFilters(remainingFilters);
    setUnassignedFlag({
      ...unassignedFlag,
      [originalFilterKey]: false,
    });
    setOpenFilter({
      ...openFilter,
      [filterKey]: {
        value: false,
      },
    });
    setSearchValue({});
    setSelectedContanier && setSelectedContanier('');
    if (
      (updateStatusFilter &&
        selectedFilters.length == 1 &&
        selectedFilters.find(
          (filter) => filter.toLowerCase() == filterKey.toLowerCase()
        )) ||
      selectedFilters.length == 0
    ) {
      setDataSource(originalDataSource);
      setUpdateStatusFilter && setUpdateStatusFilter(false);
    } else if (selectedFilters.length > 1) {
      setDataSource(dataSource);
    }

    if (
      selectedFilters?.filter(
        (filter) => filter.toLowerCase() != filterKey.toLowerCase()
      )?.length > 0
    ) {
      setDataSource(dataSource);
    } else {
      setDataSource(originalDataSource);
    }
  };

  const handleOnClickApply = (filterKey: string, originalFilterKey: string) => {
    setIsApplied(true);
    mainLocaleKey != 'vat-checker' &&
      setSelectedContanier &&
      setSelectedContanier('');
    onFilterSelect(
      originalFilterKey,
      unassignedFlag[originalFilterKey]
        ? Unassigned
        : filtersState[originalFilterKey.split('.')[0]]?.value
    );
    setSearchValue({});
    setOpenFilter({
      ...openFilter,
      [filterKey]: {
        value: false,
      },
    });
  };

  const renderMultiFilter = (
    items,
    filterKey: string,
    originalFilterKey: string
  ) => {
    if (dataSource?.length > 0) {
      return (
        <Space
          direction="vertical"
          style={{ width: '100%', paddingBottom: '10px' }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {items}
          <Divider style={{ margin: '8px 0' }} />
          {userNameFilters.includes(originalFilterKey) ? (
            <>
              <Checkbox
                style={{
                  marginBottom: '8px',
                  marginLeft: '10px',
                  fontSize: '13px',
                }}
                checked={unassignedFlag[originalFilterKey]}
                onChange={(e) =>
                  onUnassignedCheckboxChange(e, originalFilterKey)
                }
              >
                {t('main.unassigned')}
              </Checkbox>
            </>
          ) : null}
          <Space style={{ float: 'inline-end', margin: '0 10px' }}>
            <ScopeButton
              width="75px"
              height="25px"
              fontSize="12px"
              lineheight="12px"
              style={{ float: 'right' }}
              onClick={() =>
                handleOnClickFilterCancel(filterKey, originalFilterKey)
              }
              color={'#41AAAF'}
              type="default"
            >
              {t('common.cancel')}
            </ScopeButton>
            <ScopeButton
              width="75px"
              height="25px"
              fontSize="12px"
              lineheight="12px"
              style={{ float: 'right' }}
              onClick={() => handleOnClickApply(filterKey, originalFilterKey)}
              type="primary"
            >
              {t('main.apply')}
            </ScopeButton>
          </Space>
        </Space>
      );
    }
  };

  const getBadgeCount = (key) => {
    if (filtersState[key]?.value) {
      return typeof filtersState[key]?.value == 'object'
        ? filtersState[key]?.value?.length
        : 1;
    } else {
      return false;
    }
  };

  const getPopoverContent = (key) => {
    return typeof filtersState[key]?.value == 'object' &&
      filtersState[key]?.value?.length > 0
      ? filtersState[key]?.value?.map((filter) => (
          <>
            <span>{filter == 'EMPTY' ? 'Missing' : filter}</span>
            <br />
          </>
        ))
      : filtersState[key]?.value
      ? filtersState[key]?.value
      : null;
  };

  const getPlaceholder = (filterKey) => {
    return mainLocaleKey
      ? t(`${mainLocaleKey}.${filterKey}`)
      : t(`main.${filterKey}`);
  };

  const getSelectedFilterClass = (key) => {
    return selectedFilters.includes(key) ? 'selected-filter' : '';
  };

  const getDropdownStyle = (key): React.CSSProperties | undefined => {
    if (key.toLocaleLowerCase().includes('mapping')) {
      return {
        minWidth: '330px',
        width: 'fit-content',
        padding: '16px',
        paddingTop: '4px',
        fontSize: 13,
        borderRadius: 20,
      };
    } else if (userNameFilters.includes(key)) {
      return {
        minWidth: '275px',
        width: 'fit-content',
        padding: '16px',
        paddingTop: '4px',
        fontSize: 13,
        borderRadius: 20,
      };
    } else {
      return {
        minWidth: '275px',
        width: 'fit-content',
        padding: '16px',
        paddingTop: '4px',
        fontSize: 13,
        borderRadius: 20,
      };
    }
  };

  const handleOpenDateFilterDropdown = (
    open: boolean,
    filterKey: string
  ): void | undefined => {
    if (
      !open &&
      !selectedFilters.find(
        (filter) => filter.toLowerCase() == filterKey.toLowerCase()
      )
    ) {
      setDateRangeOption([]);
    }

    setOpenFilter({
      ...openFilter,
      [filterKey]: {
        value: open,
      },
    });
  };

  const handleOpenFilterDropdown = (
    open: boolean,
    filterKey: string
  ): void | undefined => {
    if (
      !open &&
      !selectedFilters.find(
        (filter) => filter.toLowerCase() == filterKey.toLowerCase()
      )
    ) {
      setFilterState(filterKey, []);
      setSearchValue({});
    }

    setOpenFilter({
      ...openFilter,
      [filterKey]: {
        value: open,
      },
    });
    setIsApplied(false);
  };

  const onSearch = (value, key) => {
    setSearchValue({ key: key, value: value });
  };

  return (
    <>
      <Row
        justify="center"
        style={{
          borderTop: haveBorder ? '1px solid #DBEAE3' : 'none',
          paddingTop: haveBorder ? 15 : 0,
        }}
      >
        <Col span={24}>
          {filters?.length > 0 && (
            <Space
              style={{
                marginBottom: 16,
                flexWrap: 'wrap',
              }}
            >
              <Typography
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '12px',
                  textAlign: 'left',
                  color: '#042A2A',
                  minWidth: 35,
                }}
              >
                {t('main.filters')}
              </Typography>
              {filters.map((key) => {
                const keys = key.split('.');
                const filterKey = keys.length > 1 ? keys[1] : keys[0];
                return filterKey.toLowerCase().includes('date') ? (
                  <FilterSelect
                    disabled={dataSource?.length == 0}
                    className={getSelectedFilterClass(keys[0])}
                    key={filterKey}
                    width="100%"
                    height="45px"
                    placeholder={getPlaceholder(filterKey)}
                    dropdownStyle={{
                      minWidth: '300px',
                      fontSize: 13,
                      borderRadius: 20,
                    }}
                    dropdownRender={() => renderDateFilter(filterKey)}
                    onDropdownVisibleChange={(open) =>
                      handleOpenDateFilterDropdown(open, filterKey)
                    }
                    open={openFilter[filterKey]?.value}
                    value={dateRangeOption}
                  >
                    <Select.Option key={filterKey}></Select.Option>
                  </FilterSelect>
                ) : (
                  <Popover
                    key={filterKey}
                    placement="topRight"
                    arrow={false}
                    trigger="hover"
                    open={
                      selectedFilters.includes(keys[0]) &&
                      hovered &&
                      hoveredFilter == filterKey
                    }
                    content={getPopoverContent(key.split('.')[0])}
                    onOpenChange={(open: boolean) => {
                      setHovered(open);
                      setHoveredFilter(filterKey);
                    }}
                    title={''}
                  >
                    <Badge
                      key={filterKey}
                      style={{ backgroundColor: '#41AAAF' }}
                      count={
                        !getBadgeCount(key.split('.')[0])
                          ? badgeCounts[key.split('.')[0]]
                          : getBadgeCount(key.split('.')[0])
                      }
                    >
                      <FilterSelect
                        disabled={dataSource?.length == 0}
                        className={getSelectedFilterClass(keys[0])}
                        key={filterKey}
                        width="100%"
                        height="45px"
                        placeholder={getPlaceholder(keys[0])}
                        mode="multiple"
                        dropdownStyle={getDropdownStyle(key)}
                        dropdownRender={(items) =>
                          renderMultiFilter(items, filterKey, key)
                        }
                        onDropdownVisibleChange={(open) =>
                          handleOpenFilterDropdown(open, filterKey)
                        }
                        open={openFilter[filterKey]?.value}
                        onChange={(value) =>
                          handleFilterChange(key.split('.')[0], value)
                        }
                        value={
                          filtersState[key.split('.')[0]]?.value?.length > 0
                            ? filtersState[key.split('.')[0]]?.value
                            : []
                        }
                        showSearch={key.split('.')[0] != 'accountActivated'}
                        filterOption={(input, option) => {
                          if (
                            key.split('.')[0] == 'country' &&
                            mainLocaleKey != 'vat-checker'
                          ) {
                            return option?.title
                              ?.toLowerCase()
                              .includes(input.toLowerCase());
                          } else if (
                            key.split('.')[0] == 'viesStatus' ||
                            (key.split('.')[0] == 'accountType' &&
                              mainLocaleKey == 'users')
                          ) {
                            return option?.key
                              ?.toLowerCase()
                              .includes(input.toLowerCase());
                          } else {
                            return option?.value
                              ?.toLowerCase()
                              .includes(input.toLowerCase());
                          }
                        }}
                        onSearch={(e) => onSearch(e, key.split('.')[0])}
                      >
                        {renderFilterOptions(key)}
                      </FilterSelect>
                    </Badge>
                  </Popover>
                );
              })}
              <LinkButton
                style={{ fontWeight: 600 }}
                color="#879494"
                type="link"
                onClick={clearFilters}
              >
                {t('main.clear-filters')}
              </LinkButton>
            </Space>
          )}
          {dataSource?.length > 0 && enableColumnsCustomization ? (
            <IconButton
              type="default"
              style={{ float: 'right', height: '30px' }}
              icon={<SettingOutlined width={'16px'} height={'16px'} />}
            >
              {t('main.customise')}
            </IconButton>
          ) : (
            renderRightComponent && (
              <div style={{ float: 'right', height: '32px' }}>
                {renderRightComponent}
              </div>
            )
          )}
          <StyledTable
            className={className}
            dataSource={dataSource}
            columns={columns}
            rowKey={(record) => record.key}
            locale={{
              triggerDesc: t('main.sortDescending'),
              triggerAsc: t('main.sortAscending'),
              cancelSort: t('main.clickToCancel'),
            }}
            size={size}
            pagination={
              pagination && dataSource?.length > rowsPerPage
                ? {
                    pageSize: rowsPerPage,
                    itemRender: renderPaginationButtons,
                    showTotal: renderPaginationText,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                  }
                : false
            }
            rowheight={rowHeight}
            title={() => titleComponent}
            showSorterTooltip={{
              overlayInnerStyle: {
                fontSize: 13,
                fontWeight: 400,
                backgroundColor: 'white',
                color: '#879494',
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};
