import React from 'react';
import { MenuProps, Space } from 'antd';
import { DataLogActions, DataLogStatus } from 'store/app/enums';
import { User } from 'store/auth/models';
import { VatRunData } from 'store/vat/models';
import { checkDisabled } from './check-disabled';
import { IconContextButton, ScopeButton } from 'components/Custom/buttons';
import { Link } from 'react-router-dom';
import { AccountType } from 'store/api/enums';

export const getNextButton = (
  handleMenuClick: (key: DataLogActions) => void,
  vatRunData: VatRunData,
  currentUser: User,
  checkClient: () => boolean
) => {
  const handleClick = (action: DataLogActions) => {
    handleMenuClick(action);
  };
  const setNotApproved = () => {
    handleMenuClick(DataLogActions.SET_NOT_APPROVED);
  };
  // const showNoApprove =
  //   vatRunData.status &&
  //   ((DataLogStatus[vatRunData.status] === DataLogStatus.AWAITING_APPROVAL &&
  //     (currentUser.accountUuid === vatRunData.reviewerId ||
  //       currentUser.accountUuid === vatRunData.preparerId ||
  //       AccountType[currentUser.accountType] === AccountType.OWNER)) ||
  //     (DataLogStatus[vatRunData.status] ===
  //       DataLogStatus.AWAITING_CLIENT_APPROVAL &&
  //       currentUser.accountUuid === vatRunData.clientId));

  const showNoApprove =
    vatRunData.status &&
    DataLogStatus[vatRunData.status] ===
      DataLogStatus.AWAITING_CLIENT_APPROVAL &&
    currentUser.accountUuid === vatRunData.clientId;

  let selecetedAction = DataLogActions.SAVE_VAT_RETURN;
  if (vatRunData.status) {
    switch (DataLogStatus[vatRunData.status]) {
      case DataLogStatus.SAVED:
        selecetedAction = DataLogActions.SET_AWAITING_APPROVAL;
        break;
      case DataLogStatus.AWAITING_APPROVAL:
        if (
          vatRunData.status &&
          DataLogStatus[vatRunData.status] ===
            DataLogStatus.AWAITING_APPROVAL &&
          (currentUser.accountUuid === vatRunData.reviewerId ||
            currentUser.accountUuid === vatRunData.preparerId ||
            AccountType[currentUser.accountType] === AccountType.OWNER)
        ) {
          selecetedAction = DataLogActions.SET_INTERNAL_APPROVED;
        }
        break;
      case DataLogStatus.INTERNAL_APPROVED:
        selecetedAction = DataLogActions.SEND_FOR_CLIENT_APPROVAL;
        break;
      case DataLogStatus.AWAITING_CLIENT_APPROVAL:
        if (currentUser.accountUuid === vatRunData.clientId) {
          selecetedAction = DataLogActions.SET_CLIENT_APPROVED;
        } else selecetedAction = DataLogActions.LOCK_AS_APPROVED;
        break;
      case DataLogStatus.CLIENT_APPROVED:
      case DataLogStatus.LOCKED:
        selecetedAction = DataLogActions.MARK_AS_SUBMITTED;
    }
  }

  return (
    <Space>
      <>
        {!checkClient() && (
          <ScopeButton
            onClick={() => handleClick(selecetedAction)}
            type="primary" //@ts-ignore
            width={showNoApprove ? '160px' : '240px'}
            //disabled={checkClient()}
          >
            {selecetedAction === DataLogActions.SET_INTERNAL_APPROVED ||
            selecetedAction === DataLogActions.SET_CLIENT_APPROVED
              ? 'Approve'
              : selecetedAction === DataLogActions.MARK_AS_SUBMITTED
              ? 'Submitted with authorities'
              : selecetedAction}
          </ScopeButton>
        )}
        {showNoApprove && (
          <ScopeButton
            onClick={() => setNotApproved()}
            type="default" //@ts-ignore
            width="160px"
          >
            Reject
          </ScopeButton>
        )}
      </>
    </Space>
  );
};
