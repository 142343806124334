import React, { useEffect } from 'react';
import { UserDetails } from 'store/signup/models';
import { useTranslation } from 'react-i18next';
import {
  BusinessSize,
  BusinessType,
  Country,
  CountryFlag,
} from 'store/common/models';
import {
  Col,
  Form,
  Input,
  Row,
  Typography,
  Select,
  Checkbox,
  Spin,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { RuleObject } from 'antd/es/form';
import routes from 'routes';
import { Provider } from 'store/api/enums';
import ReCAPTCHA from 'react-google-recaptcha';
import Logo from 'assets/images/generateTAX-logo.svg';
import { CenterLogo } from 'components/Custom/logo';
import {
  BoldSpanLargeSize,
  ColoredBoldSpanLargeSize,
  SSOLoginSpan,
  BoldSpanMidSize,
} from 'components/Custom/spans';
import { SSOLoginButton, ScopeButton } from 'components/Custom/buttons';
import { FormWrapper } from 'components/Custom/wrappers';
import { FormInput } from 'components/Custom/inputs';
import { FormSelect } from 'components/Custom/selects';
import { SSOImg } from 'components/Custom/images';
import ImgWithXeroSignIn from 'assets/images/xero_icon.png';
import ImgWithGoogleSignIn from 'assets/images/google-icon.png';
import ImgWithMicrosoftSignIn from 'assets/images/microsoft-icon.png';
import { renderPhoneCodeOption } from 'utils/render-countries-codes';

interface SignUpFormProps {
  onRegister: (user: UserDetails, gCaptchaResponse: string) => void;
  countries: Country[];
  countriesExternalData: CountryFlag[];
  businessTypes: BusinessType[];
  businessSizes: BusinessSize[];
  scopeCountries: Country[];
  loading: boolean;
  callProviderSSO: (provider: Provider) => void;
  form: any;
  ssoSignUp: boolean;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({
  onRegister,
  countries,
  countriesExternalData,
  businessTypes,
  businessSizes,
  scopeCountries,
  loading,
  callProviderSSO,
  form,
  ssoSignUp,
}) => {
  const { t } = useTranslation();

  const [checked, setChecked] = React.useState(false);
  const onXeroClick = () => {
    callProviderSSO(Provider.XERO);
  };

  const onGoogleClick = () => {
    callProviderSSO(Provider.GOOGLE);
  };

  const onMicrosoftClick = () => {
    callProviderSSO(Provider.AZURE);
  };

  useEffect(() => {
    countries.forEach((country) => {
      country.flag = countriesExternalData.find(
        (flag) => flag.cca2.toLowerCase() == country.iso.toLowerCase()
      )?.flags.png;
    }, []);
  }, [countries, countriesExternalData]);

  const onCheckboxChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const validation = (
    _rule: RuleObject,
    _value: any,
    callback: (error?: string) => void
  ) => {
    if (checked) {
      return callback();
    }
    return callback(t('signup.terms-warning'));
  };
  const recaptchaRef = React.useRef();

  const handleSubmit = async (userDetails: UserDetails) => {
    // @ts-ignore
    const gCaptchaResponse: string = await recaptchaRef.current.executeAsync();
    userDetails.phone = userDetails.code + userDetails.phone;
    delete userDetails['code'];
    onRegister(userDetails, gCaptchaResponse);
  };

  const renderCountryWithFlag = (country: Country): React.ReactNode => {
    return (
      <div style={{ display: 'flex' }}>
        <img
          width={'24px'}
          height={'16px'}
          alt={''}
          src={country.flag}
          style={{
            marginLeft: '5px',
            marginRight: '10px',
            marginTop: '3px',
          }}
        />
        {country.name}
      </div>
    );
  };

  const renderOtherCountries = () => {
    const supportedCountries = countries.filter(
      (country) => country.scopeSupported
    );
    const notSupportedCountries = countries.filter(
      (country) => !country.scopeSupported
    );
    return (
      <>
        <Select.OptGroup key="supported" label={t('signup.supported')}>
          {supportedCountries &&
            supportedCountries.map((c) => {
              return (
                <Select.Option
                  key={c.iso}
                  value={
                    c.name.includes(' ')
                      ? c.iso3.toLowerCase()
                      : c.name.toLowerCase()
                  }
                >
                  {renderCountryWithFlag(c)}
                </Select.Option>
              );
            })}
        </Select.OptGroup>
        <Select.OptGroup key="not-yet" label={t('signup.not-yet')}>
          {notSupportedCountries &&
            notSupportedCountries.map((c) => {
              return (
                <Select.Option
                  key={c.iso}
                  value={
                    c.name.includes(' ')
                      ? c.iso3.toLowerCase()
                      : c.name.toLowerCase()
                  }
                >
                  {renderCountryWithFlag(c)}
                </Select.Option>
              );
            })}
        </Select.OptGroup>
      </>
    );
  };

  return (
    <FormWrapper>
      <Spin spinning={loading} size="large">
        <Row justify="center" style={{ paddingTop: '20px' }}>
          <Col span={12}>
            <Row justify="center">
              <CenterLogo src={Logo} />
            </Row>
            <Row justify="center">
              <Typography.Title
                level={1}
                style={{
                  fontSize: '24px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  marginBottom: 0,
                  textAlign: 'center',
                }}
              >
                {t('signup.signup-title-try')}
                <BoldSpanLargeSize>
                  {' '}
                  {t('signup.signup-app-title')}{' '}
                </BoldSpanLargeSize>
                {t('signup.signup-title-for')}
                <ColoredBoldSpanLargeSize>
                  {' '}
                  {t('signup.signup-title-free-tag')}
                </ColoredBoldSpanLargeSize>
              </Typography.Title>
            </Row>
            <Row
              style={{
                display: 'flex',
                flexFlow: 'row',
                marginTop: '20px',
                justifyContent: 'center',
              }}
            >
              <SSOLoginButton
                type="primary" //@ts-ignore
                minwidth="250px"
                marginright="10px"
                onClick={onXeroClick}
              >
                <SSOImg src={ImgWithXeroSignIn} />
                <SSOLoginSpan>{t('login.xero-login')}</SSOLoginSpan>
              </SSOLoginButton>
              <SSOLoginButton
                type="primary" //@ts-ignore
                minwidth="250px"
                marginright="10px"
                onClick={onGoogleClick}
              >
                <SSOImg src={ImgWithGoogleSignIn} />
                <SSOLoginSpan>{t('login.google-login')}</SSOLoginSpan>
              </SSOLoginButton>

              <Tooltip title="Coming soon">
                <SSOLoginButton
                  type="primary" //@ts-ignore
                  minwidth="264px"
                  onClick={onMicrosoftClick}
                >
                  <SSOImg height={36} src={ImgWithMicrosoftSignIn} />
                  <SSOLoginSpan>{t('login.microsoft-login')}</SSOLoginSpan>
                </SSOLoginButton>
              </Tooltip>
            </Row>
            <Row justify={'center'} align={'middle'} style={{ height: '50px' }}>
              <Col
                span={4}
                style={{ backgroundColor: '#d5dce0', height: '1px' }}
              />
              <Col
                span={2}
                style={{
                  textAlign: 'center',
                  color: '#042A2A',
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontWeight: 400,
                }}
              >
                {t('signup.OR')}
              </Col>
              <Col
                span={4}
                style={{ backgroundColor: '#d5dce0', height: '1px' }}
              />
            </Row>
            <Row justify="center">
              <Typography.Text
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'center',
                  marginBottom: '30px',
                  marginTop: '20px',
                  color: '#042A2A',
                }}
              >
                {t('signup.signup-description')}
              </Typography.Text>
            </Row>
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              style={{ marginBottom: '30px' }}
              form={form}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.G_CAPTCHA_SITE_KEY}
              />
              <Row justify="center" align={'middle'} gutter={24}>
                <Col span={12} style={{ paddingLeft: '50px' }}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                  >
                    <FormInput
                      width="100%"
                      placeholder={t('signup.first-name')}
                      disabled={ssoSignUp}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                  >
                    <FormInput
                      placeholder={t('signup.last-name')}
                      disabled={ssoSignUp}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: '50px' }}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                      {
                        type: 'email',
                        message: t('signup.invalid-email'),
                      },
                    ]}
                  >
                    <FormInput
                      width="100%"
                      placeholder={t('signup.email')}
                      disabled={ssoSignUp}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                  <Form.Item
                    name="code"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                    style={{ width: '29%', marginRight: '2%' }}
                  >
                    <FormSelect //@ts-ignore
                      width={'100%'}
                      height="45px"
                      placeholder={t('signup.code')}
                      dropdownStyle={{ minWidth: '330px' }}
                      optionLabelProp="value"
                      id="counties-code"
                      bgcolor="#FFFFFF"
                      showSearch={true}
                      filterOption={(input, option) =>
                        option?.key?.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {countriesExternalData &&
                        countriesExternalData.map((c) =>
                          renderPhoneCodeOption(c)
                        )}
                    </FormSelect>
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                    style={{ width: '59%' }}
                  >
                    <FormInput width={'100%'} placeholder={t('signup.phone')} />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: '50px' }}>
                  <Form.Item
                    name="country"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                  >
                    <FormSelect //@ts-ignore
                      width="100%"
                      height="45px"
                      placeholder={t('signup.country')}
                      bgcolor="#FFFFFF"
                      showSearch={true}
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {countries &&
                        countries.map((c) => (
                          <Select.Option
                            key={c.iso}
                            value={c.iso}
                            label={c.name}
                          >
                            {renderCountryWithFlag(c)}
                          </Select.Option>
                        ))}
                    </FormSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="companyName"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                  >
                    <FormInput placeholder={t('signup.company-name')} />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: '50px' }}>
                  <Form.Item name="personType">
                    <FormSelect //@ts-ignore
                      width="100%"
                      height="45px"
                      placeholder={t('signup.business-type')}
                      bgcolor="#FFFFFF"
                    >
                      {businessTypes &&
                        businessTypes.map((bt) => (
                          <Select.Option key={bt.id} value={bt.id}>
                            {bt.name}
                          </Select.Option>
                        ))}
                    </FormSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="companySize">
                    <FormSelect //@ts-ignore
                      height="45px"
                      placeholder={t('signup.business-size')}
                      bgcolor="#FFFFFF"
                    >
                      {businessSizes &&
                        businessSizes.map((bs) => (
                          <Select.Option
                            key={bs.id}
                            value={parseInt(bs.id.replace('_', ''))}
                          >
                            {bs.name}
                          </Select.Option>
                        ))}
                    </FormSelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row
                justify="center"
                align={'middle'}
                style={{ textAlign: 'center' }}
              >
                <Col
                  span={24}
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  <Typography.Text
                    style={{
                      color: '#042A2A',
                      fontSize: '14px',
                      lineHeight: '24px',
                      fontWeight: 400,
                    }}
                  >
                    {t('signup.where-to-use')}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Form.Item name="dduse">
                    <FormSelect //@ts-ignore
                      width="90%"
                      height="45px"
                      mode="multiple"
                      textalign={'start'}
                      placeholder={t('signup.other-countries')}
                      bgcolor="#FFFFFF"
                    >
                      {renderOtherCountries()}
                    </FormSelect>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="Terms" rules={[{ validator: validation }]}>
                    <Checkbox checked={checked} onChange={onCheckboxChange} />
                    <Typography.Text
                      className="link-text"
                      style={{ fontSize: '13px', marginLeft: '10px' }}
                    >
                      {t('signup.i-agree-to')}{' '}
                      <Link
                        className="link"
                        to={routes.termsOfUse}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('signup.terms')}
                      </Link>{' '}
                      {t('common.and')}{' '}
                      <a
                        className="link"
                        href={routes.privacyPolicy}
                        target="_blank" //rel="noreferrer"
                        rel="noreferrer"
                      >
                        {t('signup.privacy')}
                      </a>
                    </Typography.Text>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item style={{ marginBottom: '12px' }}>
                    <ScopeButton type="primary" htmlType="submit">
                      {t('common.sign-up')}
                    </ScopeButton>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Typography.Text style={{ fontSize: '13px' }}>
                    <BoldSpanMidSize>
                      {t('signup.already-have-account')}{' '}
                    </BoldSpanMidSize>
                    <Link className="sign-up-link" to={routes.login}>
                      {t('login.login')}
                    </Link>
                  </Typography.Text>
                </Col>
                <Form.Item name="subIdentifier">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name="tokenCode">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name="realmId">
                  <Input type="hidden" />
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </FormWrapper>
  );
};
